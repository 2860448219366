<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog" >
      <div class="modal-content" >
        <div class="modal-header header_modal_icon" style="background-color: var(--color-text-reservation);">
          <div class="modal-title " style="color: white;">{{title}} </div>
          <img :src="cross" @click="onClose" class="cross cursor-pointer" />
        </div>
        <div class="modal-body">
          <div  class="error-message text_24">{{message}}</div>
        </div>
        <div class="modal-footer">
          <button type="button" @click="onConfirm" class="btn btn-primary">はい</button>
          <button type="button" @click="onClose" class="btn">いいえ</button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
import cross from "../../../../../assets/images/white-cross.svg";
import moment from "moment"
export default {
  data() {
    return {
      cross:cross,
      title:'確認',
      message:'入力途中です。入力した内容を全て破棄しタイムテ\n' +
          'ーブルの画面へ戻ってよろしいですか?'
    };
  },
  props:{
    date: Date,
    default:null
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onConfirm() {
      const date = moment(this.date).format('YYYY-MM-DD')
      window.location.href = `/shop_manager/time_table?date=${date}`
    }
  }
}
</script>

<style scoped>
.modal-dialog {
  z-index: 2007;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}
.modal{
  z-index:2006
}
.message-wrapper{
  width: 100%;
  text-align: left;
  padding-top: 16px;
}
.cross {
  width: 20px;
  color: white;
  margin-left: auto;
}
.modal-header{
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: center;
}
.modal-content{
  min-height: calc(100vh - 200px);
  border-radius: 12px;
}
.modal-footer{
  gap: 10px;
}
.btn{
  border: 1px solid var(--gray);
  border-radius: 5px;
}
.modal-title{
  margin-left: auto;
}
@media only screen
and (min-device-width: 768px){
  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }
  .modal-content{
    min-height: calc(100vh - 400px);
  }
  .error-message{
    font-size: 18px;
  }
}
</style>