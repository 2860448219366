<template>
  <div class="reservation-modal">
    <div class="modal-content">

      <div class="heading">
        <span style="float: left; color: white">予約ブロック登録</span>
        <span @click="onClose" title="Close" class="title cross-button cursor-pointer">x</span>
      </div>


      <div class="modal-body pd-0" id="modal-body">
        <div class="content-and-footer-container">
          <div class="content-div">
            <div class="inside-container">
              <div class="select-container" @click="toggleSelectTables">
                <input type="checkbox" class="checkmark" :checked="select_all">全テーブルを選択する
              </div>
              <span class="reserve-text">テーブル</span>
              <div class="row">
                <v-select
                    v-model="shop_table_ids"
                    :options="shop_tables"
                    :reduce="o => o.id"
                    label="full_name"
                    :clearable="false"
                    :searchable="false"
                    :multiple="true"
                    placeholder="テーブル名"
                    class="form_select_table input-field-font"
                    ref="selectMenu"
                    @click.native.stop="forceOpen"
                    :dropdown-should-open="() => dropDownSelection"
                >
                  <template slot="option" slot-scope="option">
                    <input
                        class="checkmark"
                        type="checkbox"
                        :checked="isChecked(option.id)"
                        @click.stop="toggleSelection(option)"
                    />
                    {{ option.name }}
                    <span class="second_span">[{{ option.number_of_people_min }}-{{ option.number_of_people_max }}人]</span>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <span class="single_line first_span">
                    {{ option.name }}
                    </span>
                    <span class="second_span">[{{ option.number_of_people_min }}-{{ option.number_of_people_max }}人]</span>
                  </template>
                </v-select>
                <small v-for="error in errors['shop_table_ids']" :key="error" class="error">{{ error }}</small>
              </div>
              <span class="reserve-text" style="margin-top: 1.2rem">日付</span>
              <div class="row">
                <DatePicker
                    input-class="form-control input-field-font cursor-pointer"
                    v-model="start_date"
                    placeholder="選択してください"
                    value-type="format"
                    format="YYYY-MM-DD"
                    :clearable="false">
                  <template v-slot:icon-calendar>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.6263 0.958984V2.87565H13.3763V0.958984H15.293V2.87565H19.1263C19.6556 2.87565 20.0846 3.30472 20.0846 3.83398V19.1673C20.0846 19.6966 19.6556 20.1257 19.1263 20.1257H1.8763C1.34703 20.1257 0.917969 19.6966 0.917969 19.1673V3.83398C0.917969 3.30472 1.34703 2.87565 1.8763 2.87565H5.70964V0.958984H7.6263ZM18.168 10.5423H2.83464V18.209H18.168V10.5423ZM5.70964 4.79232H2.83464V8.62565H18.168V4.79232H15.293V6.70898H13.3763V4.79232H7.6263V6.70898H5.70964V4.79232Z" fill="var(--color-text-reservation)"/>
                    </svg>
                  </template>
                </DatePicker>
                <small v-for="error in errors['start_date']" :key="error" class="error">{{ error }}</small>
              </div>

            </div>
            <div class="inside-container time-period-div">
              <span class="reserve-text">ブロック時間帯</span>
              <div v-for="(time, index) in time_blocks.filter((item) => {return (item._destroy === false)})" v-bind:key="index">

                <div class="row">
                  <div>
                    <DatePicker
                        input-class="form-control custom-input-field-font cursor-pointer"
                        v-model="time.start_time"
                        value-type="format"
                        format="HH:mm"
                        type="time"
                        :show-second="false"
                        :minute-step="15"
                        :clearable="false"
                        placeholder="選択してください">
                      <template v-slot:icon-calendar>
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 7L0.803849 0.249999L11.1962 0.25L6 7Z" fill="var(--color-text-reservation)"/>
                        </svg>
                      </template>
                    </DatePicker>
                    <small v-for="error in errors[`start_time_${time_blocks.indexOf(time)}`]" :key="error"
                           class="error">{{ error.replace(`Start time ${time_blocks.indexOf(time)}`, '開始時間') }}</small>
                  </div>


                  <div class="blacklit-div">
                    <span>~</span>
                  </div>

                  <div>
                    <DatePicker
                        input-class="form-control custom-input-field-font cursor-pointer"
                        v-model="time.end_time"
                        value-type="format"
                        format="HH:mm"
                        type="time"
                        :show-second="false"
                        :minute-step="15"
                        :clearable="false"
                        placeholder="選択してください">>
                      <template v-slot:icon-calendar>
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 7L0.803849 0.249999L11.1962 0.25L6 7Z" fill="var(--color-text-reservation)"/>
                        </svg>
                      </template>
                    </DatePicker>
                    <small v-for="error in errors[`end_time_${time_blocks.indexOf(time)}`]" :key="error"
                           class="error">{{ error.replace(`End time ${time_blocks.indexOf(time)}`, '終了時間') }}</small>

                  </div>
                  <span @click="removeItemTime(time)" class="date-remover">削除</span>
                </div>
              </div>
            </div>
            <div class="inside-container row button-row add-timezone-row">
              <button
                  v-if="time_blocks.filter((item) => {return (item._destroy == false)}).length < 5"
                  type="button" class="btn btn-date-addition"
                  id="add-row" @click="addItemTime">
                追加
              </button>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary mr_small" @click="onSave">{{isEdit===true?"保存する":"予約ブロック"}}</button>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import moment from 'moment';
import {decode_data} from "../../shared/util";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Http from "../../shared/http"

export default {
  components: {DatePicker, vSelect},
  props: {
    reservation: {
      type: Object,
    },
    datetime_blocks: {
      type: Array,
    },
    workingTime: {
      type: Object,
    }
  },
  computed: {
    isChecked() {
      return (optionId) => this.shop_table_ids && this.shop_table_ids.includes(optionId);
    }
  },

  data() {
    return {
      isEdit:false,
      dropDownSelection: false,
      reservationId: null,
      start_date: moment(new Date()).format('YYYY-MM-DD'),
      start_time: '',
      errors: {},
      shop_tables: decode_data("#data-shop-tables"),
      end_time: '',
      shop_table_ids: [],
      time_blocks: [{
        id: null,
        start_time: null,
        end_time: null,
        _destroy: false
      }],
      reservations: [],
      select_all: false
    };
  },
  async mounted() {
    document.addEventListener('click', this.checkOutsideClick);
  },
  watch: {
    reservation: {
      handler(r) {
        this.errors = {}
        this.reservationId = r.id
        if (this.datetime_blocks.length > 0) {
          this.time_blocks = this.datetime_blocks
          this.isEdit = true
        } else {
          this.time_blocks = [{
            index: 1,
            start_time: moment(r.start_time).format('HH:mm'),
            end_time: moment(r.end_time).format('HH:mm'),
            _destroy: false
          }]
          this.isEdit = false

        }

        this.shop_table_ids = r.shop_table_ids
        this.start_date = moment(r.start_date).format('YYYY-MM-DD')
      },
      deep: true
    },
  },
  methods: {
    toggleSelection(option) {
      if (!Array.isArray(this.shop_table_ids)) {
        this.shop_table_ids = [];
      }
      const index = this.shop_table_ids.indexOf(option.id);
      if (index === -1) {
        this.shop_table_ids = [...this.shop_table_ids, option.id];
      } else {
        this.shop_table_ids = this.shop_table_ids.filter(id => id !== option.id);
      }
    },
    checkOutsideClick(event) {
      if (this.dropDownSelection && this.$refs.selectMenu && !this.$refs.selectMenu.$el.contains(event.target)) {
        this.outsideClick();
      }
    },
    forceOpen() {
      this.$refs.selectMenu.isMenuActive = true;
      this.dropDownSelection = true
    },
    outsideClick() {
      this.$refs.selectMenu.isMenuActive = false;
      this.dropDownSelection = false
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('modal-body');
      elmnt.scrollTo(0, 0);
    },
    convertTime(time) {
      return moment(time).format("YYYY/MM/DD")
    },
    onClose() {
      this.select_all = false;
      this.$emit('close');
    },
    async onSave() {
      this.$emit('loading', true);
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      this.time_blocks = this.time_blocks.map((v, index) => ({...v, index: index}))
      let time_line = []

      this.time_blocks.forEach(element => {
        const convertStart = moment(`${this.start_date} ${element.start_time}`);
        const convertEnd = moment(`${this.start_date} ${element.end_time}`);
        let start_time = element.start_time;
        let end_time = element.end_time;
        if (element.start_time && moment(this.workingTime.start_time) > convertStart) {
          const hour = convertStart.hour()
          const minute = convertStart.format("mm")
          start_time = `${24 + hour}:${minute}`
        }

        if (element.end_time && moment(this.workingTime.start_time) > convertEnd) {
          const hour = convertEnd.hour()
          const minute = convertEnd.format("mm")
          end_time = `${24 + hour}:${minute}`
        }

        time_line.push({
          id: element.id,
          index: element.index,
          start_time: start_time,
          end_time: end_time,
          _destroy: element._destroy
        })
      });

      time_line.sort((a, b) => a.start_time?.localeCompare(b.start_time));

      await Http.post(`${slug}/shop_manager/reservation_block`, {
        reservation: {
          start_date: this.start_date,
          shop_table_ids: this.shop_table_ids,
          time_blocks: time_line,
          number_of_people: 1,
          reservation_type: 'admin',
          last_name: 'admin',
          state: 30,
        },
      })
          .then((response) => {
            this.$emit('reload')
            this.$emit('close')
          })
          .catch((error) => {
            this.$emit('loading', false);
            console.log(error)
            this.errors = error.response.data
          });
    },
    addItemTime() {
      this.time_blocks.push({
        id: null,
        start_time: null,
        end_time: null,
        _destroy: false
      })
    },
    removeItemTime(item) {
      const index = this.time_blocks.indexOf(item);
      this.time_blocks[index]._destroy = true;
    },
    toggleSelectTables(){
      this.select_all = !this.select_all
      this.selectAllTable(this.select_all)


    },
    selectAllTable(select) {
      if(select){
        this.shop_table_ids = this.shop_tables.map(table => table.id);
      }
      else{
        this.shop_table_ids = []
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.date-remover{
  color: red;
  font-size: 15px;
  white-space: nowrap;
  margin-top: 0.7rem;
  margin-left: 0.7rem;
  cursor: pointer;
}


.reserve-text {
  font-size: 12px;
  color: var(--color-medium-grey);
  font-weight: 400;
}
.btn-date-addition {
  background-color: var(--color-text-reservation);
  color: #FFFFFF !important;
  border: none;
}
.btn-date-addition:hover {
  background-color: var(--color-text-reservation) !important;
  border: none !important;
  color: #FFFFFF !important;
}
.inside-container {
  gap: 6px !important;
}
.select-container{
  font-size: 24px;
  max-width: fit-content;
}
.select-container input[type=checkbox]:checked {
  background-color: var(--color-text-reservation) !important;
  color: #ffffff !important;
}
.select-container input[type="checkbox"] {
  /* Default styles */
  width: 20px;
  height: 20px;
  accent-color: var(--color-text-reservation) !important;
  margin-right: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

/* Style the checkbox when checked */
.select-container input[type="checkbox"]:checked {
  background-color: var(--color-text-reservation);
}
.modal {
  background-color: #2125298a;
}

.modal-mb {
  margin-bottom: calc(100vh - 98%);
}

.pd-0 {
  padding: 0 0 20px 0 !important;
}

.btn-unset {
  background: transparent;
  border: 2px solid var(--color-main) !important;
  color: var(--color-main);
}

.modal-header, .modal-body {
  padding: 0.6rem 1rem;
}

.modal-dialog {
  z-index: 1050;
  max-width: 630px;
}

.mx-datepicker {
  width: 100%;
}

#reservation-types option:disabled {
  color: #999;
}

.display-none {
  display: none !important;
}

.disable {
  background: #cccccc;
  cursor: not-allowed;
  pointer-events: none;
}

.text_state {
  text-align: center;
  width: 100%;
}

.modal-header {
  align-items: center;
}

.image_set {
  object-fit: cover;
}

.box_list {
  height: 100px;
  display: flex;
  align-items: center;
  width: 100%;
}

.list_status {
  width: 100%;
  padding-left: 20px;
}

.customer-details {
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.customer-right-details {
  text-align: right;
  color: blue;
  font-size: 16px;
}

.column-1 {
  padding-left: 40px;
  width: 160px;
}

.column-2 {
  width: 110px;
}

.column-3 {
  width: 155px;
  margin-left: 10px;
}

.column-4 {
  margin-left: 10px;
  width: 135px;
}

.d-flex-content {
  display: flex;
}

.reservation_null {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media screen and (max-width: 480px) {
  .count-reservation {
    margin-left: 0 !important;
  }
  .modal-mb {
    margin-bottom: calc(100vh - 150%);
  }
  .d-block-content {
    display: block;
  }

  .column-1 {
    padding-left: 30px;
    width: 110px;
  }
  .column-2 {
    width: 60px;
  }
  .column-3 {
    width: 85px;
    margin-left: 20px;
  }
  .column-4 {
    margin-left: 20px;
    width: 70px;
  }
}

.height-29 {
  height: 21px;
}

.unhover:hover {
  opacity: unset !important;
}

.accordion {
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  background: #fff;
  padding-bottom: 0;

  &.active {
    border-bottom: none;
  }
}


/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: #ffefe5;
  display: none;
  padding-right: 0;
  overflow: hidden;
  font-size: 12px;
}

button.accordion .icon-acc {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
  margin-top: 8px;
}

button.accordion.active .icon-acc {
  position: relative;
  margin-top: 17px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
}

.header-res {
  display: flex;
  align-items: stretch;

  .col-res-1 {
    width: 30%;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .col-res-2 {
    width: 23%;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .col-res-3 {
    width: 23%;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

.border-bottom-res {
  width: 100%;
  border-bottom: 1px solid #DEE2E6;
  margin-top: 15px;
}

.w-30-res {
  width: 30.5% !important;
}

.w-27-res {
  width: 27% !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.single_line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.first_span {
  flex: 1 1 auto;
}

.second_span {
  flex: 0 0 auto;
}

.form_select_table {
  --vs-font-size: 0;
  --vs-actions-padding: 8px 0px 8px 0px;
  --vs-controls-size: 0;
}

.form-modal-reservation {
  --vs-border-color: #DDDDDD;
}

.mb-10px {
  margin-bottom: 10px;
}

.remove-item {
  height: 40px !important;
  background-color: #BB0000;
  font-size: 20px !important;
  color: var(--color-white);
}

.block-addItem {
  text-align: center;
  display: block !important;
}

.note-block {
  width: 100%;
  border-top: 1px solid #dee2e6;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-left: 0.6rem;
  font-size: 12px;
}

.close-block {
  padding-top: 0 !important;
  margin-top: -1.9rem !important;
}

.btn-select-all-table {
  margin: -2px 12px 4px;
  padding: 4px 12px;
  font-size: 10px;

  &:focus {
    box-shadow: none;
  }
}

.heading {
  background-color: var(--color-text-reservation);
  display: flex;
  align-items: center;
  padding: 10px 30px;
  width: 100%;
  position:relative;
  justify-content: space-between;
}

.cross-button {
  font-size: 30px;
  font-weight: normal;
  color: var(--color-white);
}

.tab-div span {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-main);
}

.inside-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 5px;
  //border-bottom: 1px solid var(--color-tt-block-bg);
}

.button-row {
  justify-content: flex-start;
  padding-top: 12px;
  width: 12rem;
}

.btn {
  font-size: 16px !important;
  font-weight: normal;
  padding: 11px 22px;
}

.time-period-div {
  padding-top: 20px;
  border: none;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
  flex-wrap: nowrap !important;
}

.text-and-button-row {
  justify-content: space-between;
  align-items: center;
}

span {
  font-size: 20px;
}

.add-timezone-row {
  padding-top: 0px;
  color: #FFFFFF;
}

.blacklit-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.blacklit-div span {
  font-size: 30px;
}
.reservation-modal {
  width: 100%;
}

.form_select_table {
  width: 100%;
}

@media only screen and (min-width: 480px) and (max-width: 1000px) {
  .modal-content {
    width: 100%;
  }
}
.content-and-footer-container{
  display:flex;
  flex-direction:column;
  height:calc(100dvh - 160px);
  position:relative;
}
.content-div{
  overflow-y:scroll;
  height:100%;
  padding:12px 0;
}
.tab-div{
  padding:20px;
  border-bottom:2px solid var(--color-main);
}
.checkmark{
  accent-color: var(--color-text-reservation) !important;
}
</style>
