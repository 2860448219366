<template>
  <div class="modal-show">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div v-for="(status, index) in filteredOptions" :key="index" :class="status.class" @click="checkView(status)">
            <span class="status-label" @click="checkView(status)">{{ status.label }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop no-opacity" style="background-color: transparent;" @click="onClose"></div>
    <StateCancelModal
        v-show="showStateCancelModal"
        @close="showStateCancelModal = false"
        :reservation = reservation
        :selectedStatus = selectedStatus
        :shop = shop
    />
  </div>
</template>

<script>
import Http from "../../shared/http";
import StateCancelModal from "../time_table/components/StateCancelModal.vue";

export default {
  components: {StateCancelModal},
  props: {
    reservation: {
      type: Object
    },
    showView:{
      type: Boolean
    },
    tab:{
      type: String
    },
    shop:{
      type:Object
    }
  },
  data() {
    return {
      state_options: [
        { label: "来店", class: "sit-down", value: "seating" },
        { label: "来店待ち", class: "reserved", value: "reserved" },
        { label: '会計済', class: "accounted-for", value: 'finished'},
        { label: "予約取消", class: "reservation-cancel", value: "cancelled" },
        { label: "No show", class: "no-show", value: "not_show" }
      ],
      selectedStatus: null,
      status: this.reservation.state,
      showStateCancelModal: false,
    };
  },
  computed: {
    filteredOptions() {
      return this.state_options.filter(option => option.value !== this.reservation.state);
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },

    async updateState(status) {
      this.selectedStatus = status;
      if ((this.selectedStatus.value === "cancelled")  && !(this.reservation.email === null || this.reservation.email === '')){
        this.showStateCancelModal = true;
      }
      else {
        const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
        await Http.patch(`${slug}/shop_manager/time_table/${this.reservation.id}`, {reservation: {state: this.selectedStatus.value}})
            .then((response) => {
              this.$emit('reload')
              this.$emit('close');
              window.location.reload();
            })
            .catch((error) => {
              console.log(error);
            });
      }
    },

    checkView(status) {
      if (this.showView===true && this.tab==="reservation") {
        this.updateState(status)
      } else {
        this.selectStatus(status)
      }
    },

    async selectStatus(status) {
      this.selectedStatus = status;
      this.$emit("statusSelected", this.selectedStatus);
      this.$emit('close');
    },
  }
};
</script>

<style scoped>
.modal-show{
  position: absolute;
  top: 85px;
  display: block;
}
.modal-dialog {
  z-index: 1050;
  overflow: auto;
  min-height: 0;
}
.modal-content {
  border-radius: 12px;
  width: 230px;
}
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.status-label{
  font-size: 18px;
  padding: 10px 0px;
  font-weight: bold;
}
.reserved {
  background-color:var( --color-reserved-opacity);
  border: 2px solid var(--sub-color);
  color: var(--color-reserved-border);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.sit-down {
  background-color:var(--color-sit-down-opacity);
  border: 2px solid var(--color-seating-border);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-medium-grey);
  cursor: pointer;
}
.accounted-for {
  background-color: var(--color-accounted-for);
  border: 2px solid var(--color-medium-grey);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-medium-grey);
  cursor: pointer;
}
.reservation-cancel {
  background-color: var(--color-reservation-cancel);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-white);
  cursor: pointer;
}
.no-show {
  background-color: var(--color-no-show);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-white);
  cursor: pointer;
}
</style>
