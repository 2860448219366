<template>
  <div class="customer-form">
    <div class="form-modal-reservation">
      <div class="row" v-if="!isCreateNew">
        <div class="col-12">
          <span v-if="hasArrayRequiredLength(images,1)">最終更新日時 : {{images[0].updated_at && images[0].updated_at | formatDate}}</span>
          <button class="btn btn-primary" style="float:right;" :class="[hasArrayRequiredLength(images,10) ? 'disable-input-div' : '']" @click="triggerFileInput">写 真 追 加</button>
        </div>
      </div>
      <input ref="fileInput" type="file" @click="$event.target.value = ''" @change="onFileChange" accept="image/*" style="display: none"/>
      <div class="image-container" :class="{afterImageUploaded:hasArrayRequiredLength(images,1)}">
        <div v-for="(image, index) in images">
          <div class="image-card">
            <img class="cursor-pointer" @click="openImageModal(image.image_id)" :src="image.image_id"/>
            <i @click="removeImage(index)" class="fa fa-times cross-button" aria-hidden="true"></i>
          </div>
          <div class="text-center">
            {{image.created_at && image.created_at | formatDate}}
          </div>
        </div>
      </div>

      <div class="row ">
        <span class="input-label">お客様番号</span>
        <input v-model="number" type="text" class="form-control customer-input-field-font" placeholder="お客様番号">
        <small v-for="error in errors['number']" :key="error" class="error">{{ error }}</small>
      </div>


      <div class="row select-with-label-row without-label">
        <span class="input-label">お名前</span>
      </div>
      <div class="row select-with-label-row without-label">
        <div class="select-with-label-inside">
          <input v-model="last_name" type="text" class="form-control customer-input-field-font" placeholder="姓">
          <small v-for="error in errors['last_name']" :key="error" class="error">{{ error }}</small>
        </div>

        <div class="select-with-label-inside">
          <input v-model="first_name" type="text" class="form-control customer-input-field-font" placeholder="名">
          <small v-for="error in errors['first_name']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>
      <div class="row select-with-label-row without-label">
        <div class="select-with-label-inside">
          <input v-model="lastNameKana" type="text" class="form-control customer-input-field-font" placeholder="セイ">
          <small v-for="error in errors['last_name_kana']" :key="error" class="error">{{ error }}</small>
        </div>

        <div class="select-with-label-inside">
          <input v-model="firstNameKana" type="text" class="form-control customer-input-field-font" placeholder="メイ">
          <small v-for="error in errors['first_name_kana']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>
      <div class="row" v-if="errors['base']">
        <span class="input-label"></span>
        <div class="w-100" >
          <small v-for="error in errors['base']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>
      <div class="row ">
        <span class="input-label">TEL</span>
        <div class="w-100" >
          <input v-model="tel" type="tel" class="form-control customer-input-field-font">
          <small v-for="error in errors['tel']" :key="error" class="error">{{ error | handleTel1 }}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label"></span>
        <div class="w-100" >
          <input v-model="tel1" type="tel" class="form-control customer-input-field-font">
          <small v-for="error in errors['tel1']" :key="error" class="error">{{ error | handleTel2}}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label">Email</span>
        <div class="w-100">
          <input v-model="email" type="text" class="form-control customer-input-field-font">
          <small v-for="error in errors['email']" :key="error" class="error">{{ error | handleEmail1}}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label"></span>
        <div class="w-100" >
          <input v-model="email1" type="text" class="form-control customer-input-field-font">
          <small v-for="error in errors['email1']" :key="error" class="error">{{ error | handleEmail2}}</small>
        </div>
      </div>

      <div class="row">
        <span class="input-label">アレルギー</span>
        <textarea v-model="allergy" class="form-control customer-input-field-font" rows="3"></textarea>
        <small v-for="error in errors['allergy']" :key="error" class="error">{{ error }}</small>
      </div>

      <div class="row grid-container">
        <label v-for="property in properties" :key="property.id" class="grid-items">
          <input class="customer-checkbox" type="checkbox" :value="property.id" v-model="propertyIds">
          <i v-if="property.name==='VIP'" class="fa fa-star property-icon" aria-hidden="true"></i>
          <i v-if="property.name==='要注意'" class="fa fa-exclamation-circle property-icon" aria-hidden="true"></i>
          <span> {{ property.name }}</span>
        </label>
      </div>

      <div class="row">
        <span class="input-label">備考</span>
        <textarea v-model="remark" class="form-control customer-input-field-font" rows="3"></textarea>
        <small v-for="error in errors['remark']" :key="error" class="error">{{ error }}</small>
      </div>

      <div class="row ">
        <span class="input-label">領収書名</span>
        <div class="w-100">
          <input v-model="receiptName" type="text" class="form-control customer-input-field-font">
          <small v-for="error in errors['receipt_name']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label">自宅住所</span>
        <div class="w-100">
          <input v-model="homePostNumber" type="text" class="form-control customer-input-field-font"
                 placeholder="郵便番号" min="0">
          <small v-for="error in errors['home_post_number']" :key="error" class="error">{{ error | removeHomePostNumberLabel }}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label"></span>
        <div class="w-100">
          <input v-model="homeAddress" type="text" class="form-control customer-input-field-font" placeholder="住所">
          <small v-for="error in errors['home_address']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label">SNS 1</span>
        <div class="w-100">
          <input v-model="sns1" type="text" class="form-control customer-input-field-font">
          <small v-for="error in errors['sns1']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label">SNS 2</span>
        <div class="w-100">
          <input v-model="sns2" type="text" class="form-control customer-input-field-font">
          <small v-for="error in errors['sns2']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>
      <div class="birthday-anniversary-div">
        <div class="row ">
          <span class="input-label">誕生日</span>
          <DatePicker
              input-class="form-control customer-input-field-font"
              v-model="birthday"
              value-type="format"
              format="YYYY/MM/DD"
              :clearable="false">
            <i slot="icon-calendar"></i>
          </DatePicker>
          <small v-for="error in errors['birthday']" :key="error" class="error">{{ error }}</small>
        </div>

        <div class="row ">
          <span class="input-label">結婚記念日</span>
          <DatePicker
              input-class="form-control customer-input-field-font"
              v-model="weddingAnniversary"
              value-type="format"
              format="YYYY/MM/DD"
              :clearable="false">
            <i slot="icon-calendar"></i>
          </DatePicker>
          <small v-for="error in errors['wedding_anniversary']" :key="error" class="error">{{ error }}</small>
        </div>

        <div class="row" v-for="(item,index) in anniversaries">
          <span :title="item.anniversary_name" class="input-label">{{ item.anniversary_name }}</span>
          <div class="anniversary-input">
            <DatePicker
                input-class="form-control customer-input-field-font mr-2"
                v-model="item.anniversary"
                value-type="format"
                format="YYYY/MM/DD"
                :clearable="false">
              <i slot="icon-calendar"></i>
            </DatePicker>
            <button class="btn btn-delete" style="margin: 0;padding: 0"
                    @click="onDeleteAnniversary(index)">削除
            </button>
          </div>
        </div>
        <div class="row" v-if="errors['anniversary_name']">
          <span class="input-label"></span>
          <div class="w-100" >
            <small v-for="error in errors['anniversary_name']" :key="error" class="error">{{ error }}</small>
          </div>
        </div>
        <div class="row add-anniversary customer-input-field-font cursor-pointer mb-3" v-if="anniversaries.length<10" @click="openAnniversary">
          <i class="fa fa-plus text-primary"></i><span>記念日を追加</span>
        </div>
      </div>


      <div class="row ">
        <span class="input-label">会社名</span>
        <div class="w-100">
          <input v-model="companyName" type="text" class="form-control customer-input-field-font">
          <small v-for="error in errors['company_name']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label">部署名</span>
        <div class="w-100">
          <input v-model="departmentName" type="text" class="form-control customer-input-field-font">
          <small v-for="error in errors['department_name']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label">役職名</span>
        <div class="w-100">
          <input v-model="positionName" type="text" class="form-control customer-input-field-font">
          <small v-for="error in errors['position_name']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label">勤務先住所</span>
        <div class="w-100">
          <input v-model="companyPostNumber" type="text" class="form-control customer-input-field-font"
                 placeholder="郵便番号" min="0">
          <small v-for="error in errors['company_post_number']" :key="error" class="error">{{ error | removeCompanyPostNumberLabel}}</small>
        </div>
      </div>

      <div class="row ">
        <span class="input-label"></span>
        <div class="w-100">
          <input v-model="companyAddress" type="text" class="form-control customer-input-field-font" placeholder="住所">
          <small v-for="error in errors['company_address']" :key="error" class="error">{{ error }}</small>
        </div>
      </div>

      <div class="row">
        <span class="input-label">特徵</span>
        <textarea v-model="customerCharacteristics" class="form-control customer-input-field-font" rows="3"></textarea>
        <small v-for="error in errors['customer_characteristics']" :key="error" class="error">{{ error }}</small>
      </div>
      <div class="row">
        <span class="input-label">好きなもの</span>
        <textarea v-model="customerPreferences" class="form-control customer-input-field-font" rows="3"></textarea>
        <small v-for="error in errors['customer_preferences']" :key="error" class="error">{{ error }}</small>
      </div>

      <div class="row">
        <span class="input-label">嫌いなもの</span>
        <textarea v-model="customerDislike" class="form-control customer-input-field-font" rows="3"></textarea>
        <small v-for="error in errors['customer_dislike']" :key="error" class="error">{{ error }}</small>
      </div>

      <div class="row">
        <span class="input-label">その他1</span>
        <textarea v-model="others1" class="form-control customer-input-field-font" rows="3"></textarea>
        <small v-for="error in errors['others1']" :key="error" class="error">{{ error }}</small>
      </div>

      <div class="row">
        <span class="input-label">その他2</span>
        <textarea v-model="others2" class="form-control customer-input-field-font" rows="3"></textarea>
        <small v-for="error in errors['others2']" :key="error" class="error">{{ error }}</small>
      </div>

      <div class="row">
        <span class="input-label">性別</span>
        <div class="radio-row">
          <label v-for="item in sexOptions">
            <input type="radio" :value="item.value" v-model="sex">
            <span class="customer-radio"></span>{{ item.label }}
          </label>
        </div>
      </div>
      <!---------------------------------------------->
      <div class="customer-details">
        <div class="d-flex">
          <div class="d-flex-content d-block-content column">
            <span class="label">予約</span>
            <div class="count-reservation">
              {{ count_all_reservation }}
            </div>
          </div>
          <div class="d-flex-content d-block-content column">
            <div class="label w-80">来店</div>
            <div v-if="is_edit_count_reservations" class="count-reservation">
              <input type="number" v-model="count_reservation" class="input-edit-count" min="0"
                     oninput="validity.valid||(value='');">
            </div>
            <div v-else class="count-reservation">{{ count_reservation }}</div>
          </div>
          <div class="d-flex-content d-block-content column">
            <div class="label w-80">キャンセル</div>
            <div v-if="is_edit_count_reservations" class="count-reservation">
              <input type="number" v-model="count_cancel" class="input-edit-count" min="0"
                     oninput="validity.valid||(value='');">
            </div>
            <div v-else class="count-reservation">{{ count_cancel }}</div>
          </div>
          <div class="d-flex-content d-block-content column">
            <div class="label w-80">No Show</div>
            <div v-if="is_edit_count_reservations" class="count-reservation">
              <input type="number" v-model="count_not_show" class="input-edit-count" min="0"
                     oninput="validity.valid||(value='');">
            </div>
            <div v-else class="count-reservation">{{ count_not_show }}</div>
          </div>
        </div>
        <div class="edit-button">
          <button @click="is_edit_count_reservations = !is_edit_count_reservations"
                  class="btn btn-primary btn-unfocus btn-edit-count">編集
          </button>
        </div>
      </div>

      <div v-if="!isCreateNew" class="table_customer_modal">
        <table class="customer_table">
          <thead>
          <th>来店日</th>
          <th>予約種別</th>
          <th>ステータス</th>
          </thead>
          <tbody>
          <tr v-for="(reservation, index) in reservations" :key="index">
            <td>{{ convertTime(reservation.start_time) }}</td>
            <td v-if="reservation.course_id == null">席のみ</td>
            <td v-else>コース</td>
            <td v-if="reservation.state == 'reserved'">来店待ち</td>
            <td v-if="reservation.state == 'cancelled' ||
              reservation.state == 'restored'
              ">
              キャンセル
            </td>
            <td v-if="reservation.state == 'seating'">来店</td>
            <td v-if="reservation.state == 'finished'">会計済</td>
            <td v-if="reservation.state == 'not_show'">No Show</td>
          </tr>
          </tbody>
        </table>
        <div v-if="reservations.length == 0" class="reservation_null">
          表示するデータがありません。
        </div>
      </div>
      <PropertyModal v-if="showModalProperty" @close-modal-allergy="showModalProperty = false"
                     @loading="loading = $event"
                     :get-properties="getProperties"/>
      <AnniversaryModal v-if="showAnniversaryModal" @addAnniversary="addAnniversary"
                        @close-delete-modal="showAnniversaryModal=false"/>
      <DeleteAnniversaryModal v-if="showDeleteAnniversaryModal" @deleteAnniversary="deleteAnniversary"
                              @close-delete-modal="showDeleteAnniversaryModal=false"/>
      <ImageModal
          v-if="showImageModal"
          :imageID="imageId"
          @close="showImageModal=false"
      />
    </div>

    <div class="modal-footer" v-if="!hideCustomerSaveButton">
      <button @click="onSave" class="btn btn-primary">保存する</button>
    </div>
  </div>

</template>

<script>
import PropertyModal from "../customer/components/PropertyModal.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
import Http from "../../shared/http";
import moment from 'moment';
import AnniversaryModal from "../customer/components/AnniversaryModal.vue";
import DeleteAnniversaryModal from "../customer/components/DeleteAnniversaryModal.vue";
import ImageModal from "../customer/components/ImageModal.vue";

export default {
  components: {
    DeleteAnniversaryModal, AnniversaryModal,
    ImageModal,
    DatePicker,
    PropertyModal
  },
  data() {
    return {
      imageId:'',
      showImageModal:false,
      images:[],
      image_data:[],
      sexOptions:[
        {value:'male',label:'男性'},
        {value:'female',label:'女性'},
        {value:'other',label:'未設定'}
      ],
      customerCharacteristics: '',
      companyPostNumber: null,
      companyAddress: '',
      positionName: '',
      others2: '',
      others1: '',
      customerDislike: '',
      customerPreferences: '',
      departmentName: '',
      companyName: '',
      weddingAnniversary: null,
      sns2: '',
      sns1: '',
      address: '',
      homePostNumber: null,
      homeAddress: '',
      receiptName: '',
      email1: '',
      tel1: '',
      sex: null,
      loading: false,
      allergy: "",
      properties: [],
      errors: {},
      number: "",
      last_name: "",
      first_name: "",
      lastNameKana: "",
      firstNameKana: "",
      tel: "",
      email: "",
      remark: "",
      propertyIds: [],
      birthday: null,
      nameProperty: null,
      showModalProperty: false,
      count_reservation: 0,
      count_cancel: 0,
      count_not_show: 0,
      is_edit_count_reservations: false,
      showAnniversaryModal: false,
      showDeleteAnniversaryModal: false,
      anniversaryIndexToDelete: null,
      anniversaries: []
    };
  },
  filters: {
    removeHomePostNumberLabel(value) {
      const label = 'Home post number';
      if (value.includes(label)) {
        return value.replace(label, '');
      } else {
        return value;
      }
    },
    formatDate: function(value){
      return moment(value).format("YYYY/MM/DD")
    },
    removeCompanyPostNumberLabel(value) {
      const label = 'Company post number';
      if (value.includes(label)) {
        return value.replace(label, '');
      } else {
        return value;
      }
    },
    handleTel2(value) {
      const label = 'Tel1';
      if (value.includes(label)) {
        return value.replace(label, '');
      } else {
        return value;
      }
    },
    handleTel1(value) {
      const label = 'Tel';
      if (!value.includes(label)) {
        return value.replace(label, '');
      } else {
        return value;
      }
    },
    handleEmail1(value) {
      const label = 'Email';
      if (!value.includes(label)) {
        return value.replace(label, '');
      } else {
        return value;
      }
    },
    handleEmail2(value) {
      const label = 'Email1';
      if (value.includes(label)) {
        return value.replace(label, '');
      } else {
        return value;
      }
    },
  },
  computed: {
    count_all_reservation() {
      return (parseInt(this.count_reservation) || 0) + (parseInt(this.count_cancel) || 0) + (parseInt(this.count_not_show) || 0);
    },
  },
  props: {
    reservations: {
      type: Array,
    },
    customer: {
      type: Object,
    },
    isCreateNew: {
      type: Boolean,
    },
    hideCustomerSaveButton: {
      type: Boolean,
      required: false
    },
  },
  watch: {
    customer: {
      handler(r) {
        this.sex=r.sex;
        this.others2=r.others2;
        this.others1=r.others1;
        this.customerDislike=r.customer_dislike;
        this.customerPreferences=r.customer_preferences;
        this.customerCharacteristics=r.customer_characteristics;
        this.companyAddress=r.company_address;
        this.companyPostNumber=r.company_post_number;
        this.positionName=r.position_name;
        this.departmentName=r.department_name;
        this.companyName=r.company_name;
        this.weddingAnniversary=r.birthday
            ? moment(r.wedding_anniversary).format("YYYY/MM/DD")
            : null;
        this.sns2=r.sns2;
        this.sns1=r.sns1;
        this.homeAddress=r.home_address;
        this.homePostNumber=r.home_post_number;
        this.receiptName=r.receipt_name;
        this.tel1=r.tel1;
        this.email1=r.email1;
        this.number = r.number;
        this.last_name = r.last_name;
        this.first_name = r.first_name;
        this.firstNameKana = r.first_name_kana;
        this.lastNameKana = r.last_name_kana;
        this.tel = r.tel;
        this.email = r.email;
        this.remark = r.remark;
        this.birthday = r.birthday
            ? moment(r.birthday).format("YYYY/MM/DD")
            : null;
        this.allergy = r.allergy ? r.allergy : '';
        this.propertyIds = r.property_ids != null ? r.property_ids : [];
        this.count_reservation = r.count_reservation;
        this.count_cancel = r.count_cancel;
        this.count_not_show = r.count_not_show;
        this.is_edit_count_reservations = false;
        this.anniversaries = r.anniversaries ? r.anniversaries.map(({id, anniversary_name, anniversary}) => ({
          id: id,
          anniversary_name: anniversary_name,
          anniversary: moment(anniversary).format('YYYY/MM/DD')
        })) : [];
        this.images = r.images ? r.images : [];
      },
    },
    number(newValue) {
      if (newValue !== this.customer.number) {
        this.$emit('changeData');
      }
    },

    first_name(newValue) {
      if (newValue !== this.customer.first_name) {
        this.$emit('changeData');
      }
    },

    last_name(newValue) {
      if (newValue !== this.customer.last_name) {
        this.$emit('changeData');
      }
    },

    firstNameKana(newValue) {
      if (newValue !== this.customer.first_name_kana) {
        this.$emit('changeData');
      }
    },

    lastNameKana(newValue) {
      if (newValue !== this.customer.last_name_kana) {
        this.$emit('changeData');
      }
    },

    tel(newValue) {
      if (newValue !== this.customer.tel) {
        this.$emit('changeData');
      }
    },

    email(newValue) {
      if (newValue !== this.customer.email) {
        this.$emit('changeData');
      }
    },

    remark(newValue) {
      if (newValue !== this.customer.remark) {
        this.$emit('changeData');
      }
    },

    birthday(newValue) {
      if (
          newValue !==
          (this.customer.birthday
              ? moment(this.customer.birthday).format("YYYY/MM/DD")
              : null)
      ) {
        this.$emit('changeData');
      }
    },

    propertyIds(newValue) {
      if (
          newValue.length !==
          (this.customer.property_ids != null ? this.customer.property_ids : [])
              .length
      ) {
        this.$emit('changeData');
      }
    },
  },
  async mounted() {
    await this.getProperties();
  },
  methods: {
    openAnniversary() {
      this.showAnniversaryModal = true;
    },
    addAnniversary(newAnniversary) {
      this.anniversaries.push(newAnniversary);
    },
    deleteAnniversary() {
      if (this.anniversaryIndexToDelete !== null) {
        this.anniversaries.splice(this.anniversaryIndexToDelete, 1);
        this.showDeleteAnniversaryModal = false;
      }
    },
    onDeleteAnniversary(index) {
      this.showDeleteAnniversaryModal = true;
      this.anniversaryIndexToDelete = index
    },
    openImageModal(id){
      this.showImageModal=true;
      this.imageId=id;
    },
//      timeout(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// },
    async uploadImages(){
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value === '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/customer/customer_image/${this.customer.id}`;
      let params1 = {
        images : this.images.filter(item => item.id === undefined).map(item => item.image_id)
      }
      await Http.put(path, params1)
          .then((response) => {
            this.images = response.data.images
            this.$emit('loading', false)
          })
          .catch((error) => {
            console.log(error);
            this.$emit('loading', false)
          });

    },
    async getProperties() {
      this.loading = true;
      const slug =
          document.getElementById("slug").value === ""
              ? ""
              : `/${document.getElementById("slug").value}`;
      const path = `${slug}/shop_manager/properties/properties_manager`;
      await Http.get(path, {})
          .then((response) => {
            this.properties = response.data.properties;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    convertTime(time) {
      return moment(time).format("YYYY/MM/DD")
    },
    async onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      await this.createImage(files);
    },
    async createImage(files) {
      let readImagePromises = [];
      for (let index = 0; index < files.length; index++) {
        const maxFileSize = 10 * 1024 * 1024; // 10MB
        if (files[index].size > maxFileSize) {
          // Handle file size error
          await this.showFileSizeError(); // This should be your logic for showing the error
          return;
        }
        // Process each file
        readImagePromises.push(this.processFile(files[index]));
      }
      // Wait for all files to be processed
      await Promise.all(readImagePromises);
      await this.uploadImages(); // Move uploadImages call here to ensure it happens after processing

    },
    processFile(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = (event) => {
          const imageUrl = event.target.result;
          this.images.unshift({image_id: imageUrl});
          resolve(); // Resolve the promise after processing
        };
        reader.onerror = reject; // Reject the promise on error

        reader.readAsDataURL(file);
      });
    },
    async showFileSizeError(){
      let msg = `ファイルサイズは10MB以下である必要があります`;
      let title = 'ファイルサイズが超過している';
      let confirm = await this.$swal.fire({
        title: title,
        html: msg,
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-primary btn-fill btn-unfocus'
        },
        icon: 'warning',
        allowOutsideClick: false,
        confirmButtonText: "OK",
        buttonsStyling: false,
      });
      if (confirm.isConfirmed) {
        return
      } else {
        return
      }
    },
    hasArrayRequiredLength(arr,len=0){
      return arr?.length>=len
    },
    triggerFileInput(){
      if(this.images.length<=10) {
        this.$refs.fileInput.click();
      }
    },
    async removeImage(index) {
      const prompt = await this.$swal({
        title: "確認",
        html: `<hr><div class="swal2-content" style="font-size: 20px; margin: 0 32px;">
              <p>本当に削除しますか ? </p>
             </div><hr>`,
        customClass: {
          content: 'swal2-content',
          cancelButton: 'btn btn-danger btn-round',
          confirmButton: 'btn btn-outline-primary btn-round'
        },
        showCancelButton: true,
        cancelButtonText: "削除",
        confirmButtonText: "キャンセル",
        buttonsStyling: false,
      });
      if (prompt.dismiss==='cancel') {
        if(this.images[index].id===undefined){
          this.images.splice(index, 1)
        }
        else{
          const slug = document.getElementById('slug').value === '' ? '' : `/${document.getElementById('slug').value}`;
          const path = `${slug}/shop_manager/customer/customer_image/${this.images[index].id}`;
          await Http.delete(path, {})
              .then((response) => {
                this.errors ={}
                this.images.splice(index, 1)
              })
              .catch((error) => {
                this.errors = error.response.data;
              });
        }
      }
    },

    async onSave() {
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value === '' ? '' : `/${document.getElementById('slug').value}`;
      const path = this.isCreateNew ? `${slug}/shop_manager/customer/add` : `${slug}/shop_manager/customer/${this.customer.id}`;
      const params = {
        customer: {
          sex: this.sex,
          tel1: this.tel1,
          email1: this.email1,
          receipt_name: this.receiptName,
          home_post_number: this.homePostNumber === '' ? null : this.homePostNumber ,
          home_address: this.homeAddress,
          sns1: this.sns1,
          sns2: this.sns2,
          wedding_anniversary: this.weddingAnniversary,
          company_name: this.companyName,
          department_name: this.departmentName,
          position_name: this.positionName,
          company_post_number: this.companyPostNumber === ''?null: this.companyPostNumber,
          company_address: this.companyAddress,
          customer_characteristics: this.customerCharacteristics,
          customer_preferences: this.customerPreferences,
          customer_dislike: this.customerDislike,
          others1: this.others1,
          others2: this.others2,
          number: this.number,
          last_name: this.last_name,
          first_name: this.first_name,
          last_name_kana: this.lastNameKana,
          first_name_kana: this.firstNameKana,
          tel: this.tel,
          email: this.email,
          remark: this.remark,
          allergy: this.allergy,
          property_ids: this.propertyIds,
          birthday: this.birthday,
          count_reservation: this.count_reservation,
          count_cancel: this.count_cancel,
          count_not_show: this.count_not_show,
          customer_anniversaries: this.anniversaries,
          images: this.images
        }
      };

      if (this.isCreateNew) {
        await Http.post(path, params)
            .then((response) => {
              this.$emit('submitSuccess');
            })
            .catch((error) => {
              this.$emit('loading', false)
              document.getElementById('modal-body').scrollTo(0, 0);
              this.errors = error.response.data;
            });
      } else {
        await Http.patch(path, params)
            .then((response) => {
              this.$emit('submitSuccess');
            })
            .catch((error) => {
              this.$emit('loading', false)
              document.getElementById('modal-body').scrollTo(0, 0);
              this.errors = error.response.data
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  height: 40px;
}

.modal {
  background-color: #2125298a;
}

.customer-details {
  font-size: 16px;
  display: flex;
  position: relative;
  height: 75px;

  .label {
    font-weight: 600;
  }

  .count-reservation {
    margin-left: 10px;
  }
}

button:focus {
  outline: none;
}

.btn-fill:focus {
  outline: none !important;
}

.customer_table {
  width: 100%;
  font-size: 15px;

  th {
    border-right: 1px solid var(--color-white);
    width: 33%;
    background-color: var(--color-text-reservation);
    color: #fff;
    font-weight: unset;
    padding: 10px 0px 10px 60px;
  }

  td {
    padding: 5px 0px 5px 60px;
    border-bottom: 1px solid #DDDDDD;
  }
}

.dp-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.mr-10 {
  margin-right: 10px;
}

.disable {
  background: #E3E3E3;
  cursor: not-allowed;
  pointer-events: none;
}

.ml-10 {
  margin-left: 10px;
}

.allergy-remark {
  width: 50%;
}

.span-icon {
  width: 25px;
  height: 25px;
  border: 2px solid;
  padding-left: 2.5px;
  padding-top: 0.5px;
  margin-top: 0 !important;
  color: var(--color-main);
  margin-left: auto;
}

.icon_allergy_add {
  font-size: 20px;
}

.reservation_null {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.item_center_flex {
  align-items: center;
}

.customer-details {
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.body-content {
  padding: 1rem !important;
}

.transform-checkbox {
  width: 20px;
  height: 20px;
  accent-color: var(--color-main);
}

.d-flex-content {
  display: flex;
}

#span-icon-customer {
  position: unset !important;
}

.row {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  width: 100%;
  margin-left: 0px;
}

.form-modal-reservation {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px 30px;
}

.grid-container {
  display: grid;
  grid-template-columns:repeat(3, 1fr);
  gap: 10px;
}

.grid-items {
  display: flex;
  gap: 10px;
  align-items: center;
}

input[type="checkbox"] {
  width: 35px;
}

.mx-datepicker {
  width: 100%;
}

.anniversary-input {
  display: flex;
  width: 100%;
}

.anniversary-input .mx-datepicker {
  width: 80%;
  margin-right: 10px;
}

.v-select {
  width: 100%;
}

.edit-button {
  position: absolute;
  right: 0;
  bottom: -30px;
}

.d-flex {
  flex: 1;
  justify-content: space-between;
}

.save-button {
  display: flex;
  justify-content: center;
}

.select-with-label-inside {
  width: 50%;
}

@media screen and (max-width: 480px) {
  .count-reservation {
    margin-left: 0 !important;
  }

  .d-block-content {
    display: block;
  }

  .column-1 {
    padding-left: 30px;
    width: 95px;
  }

  .column-2 {
    width: 85px;
  }

  .column-3 {
    width: 85px;
    margin-left: 20px;
  }

  .column-4 {
    margin-left: 20px;
    width: 80px;
  }

  .customer_table {
    th {
      width: 33%;
      background-color: var(--color-main);
      color: #fff;
      font-weight: unset;
      padding: 10px 0;
      text-align: center;
    }

    td {
      padding: 5px 0;
      text-align: center;
      border-bottom: 1px solid #DDDDDD;
    }
  }
}

.modal-footer {
  justify-content: center;
  padding-top: 20px;
}

.modal-footer .btn {
  font-size: 16px !important;
}

.btn-edit-count {
  margin: 0;
  padding: 4px 8px;
  font-size: 12px;
  border-width:2px;
}

.allergy-text-area {
  line-height: 1.3;
}

.input-edit-count {
  width: 50px;
  height: 25px;
}

.birthday-row {
  position: relative;
}

.birthday-label {
  position: absolute;
  z-index: 2;
  font-size: 24px;
  left: 15px;
  top: 5px;
}

.add-anniversary {
  margin: auto;
  width: auto;
}

.btn-delete {
  width: 20%;
  margin: 0;
  padding: 0;
  border:2px solid var(--color-dusty-rose);
  background-color: var(--color-white);
  color: var(--color-dusty-rose);
}
.btn-delete:hover {
  background-color: var(--color-dusty-rose);
  color: var(--color-white);
}
.btn-delete:focus {
  outline: none !important;
}

.input-label {
  width: 200px;
  font-size: 20px;
  overflow:hidden;
  text-overflow:ellipsis;
}

.add-anniversary-button {
  padding-left: 160px;
}

.add-anniversary-button .btn {
  font-size: 16px;
  color: var(--color-text);
  position: relative;
  padding: 12px 40px;
}

.plus-icon {
  color: var(--color-main);
}

.customer-radio {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #555;
  border-radius: 50%;
  margin-right: 5px;
}

input[type="radio"]:checked + .customer-radio::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-main);
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

label {
  margin-bottom: 0px;
  display: flex;
}

.birthday-anniversary-div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid var(--color-tt-block-bg);
  border-bottom: 2px solid var(--color-tt-block-bg);
}
.image-container{
  display:flex;
  gap:10px;
}
.afterImageUploaded{
  padding-bottom:20px;
  overflow-x:auto;
  overflow-y:hidden;
}
.image-card{
  border:2px solid var(--color-main);
  position:relative;
  min-width:120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-height: 120px;
    padding:2px;
  }
}

.cross-button{
  position:absolute;
  top:0px;
  right:8px;
  color:var(--color-error);
  font-size:24px;
  cursor:pointer;
}
.property-icon{
  color:var(--color-error);
  font-size:20px;
}
input[type="checkbox"]{
  accent-color: var(--color-main);
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
.customer-images{

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow:row;
  height:100%;
  position:relative;
  width:100%;
  overflow: hidden;
}
.box
{
  padding: 20px;
  color:white;
  font-size:22px;
  border:1px solid white;
  flex:1;
  -webkit-flex:1;
  text-align:center;
  min-width:200px;

}
.disable-input-div{
  opacity: 0.5;
  pointer-events: none;
  cursor: none;
}
.customer-form{
  height: 60dvh !important;
}
.edit-button .btn-primary{
  background-color: var(--color-white) !important;
  border: 2px solid var(--color-text-reservation) !important;
  color: var(--color-text-reservation) ;
  &:hover{
    color: var(--color-text-reservation) !important;
    opacity: 0.8;
  }
}

.btn-primary {
  background-color: var(--color-text-reservation) !important;
}

</style>