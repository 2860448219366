<template>
  <div>
    <transition v-if="loading" name="fade">
      <Loading  />
    </transition>
    <transition  v-else name="fade">
      <div  class="reservation-full-screen">

        <div class="timetable-main-container" style="top: 0px; position:absolute; max-width:100%">
          <div class="row align-items-center" style="font-size: 1.5rem; height: 45px; background-color: var(--color-text-reservation); color:var(--color-background)">
            <div class="center" style="flex: 1; text-align: center;">
              予約変更
            </div>
            <div class="right" style="position: absolute; right: 10px;">
              <button class="btn btn-icon m-0 ml-1 mr-1">
                <i class="fa fa-times" style="font-size: 1.5rem; top: 1rem; color: var(--color-background);" aria-hidden="true" @click="showCloseValidationModal=!showCloseValidationModal"></i>
              </button>
            </div>
          </div>

          <div class="outer-container">
            <div class="display-date-container">
              <div class="display-date">
                <div class="justify-content-center align-items-center">
                  <!-- Previous Day Button -->
                  <button class="btn btn-icon m-0 ml-1 mr-1 mt-2" @click="prevDay">
                    <i class="fa fa-angle-left color-text" style="font-size: 2.4rem; top: 14px;" aria-hidden="true"></i>
                  </button>

                  <!-- Date Picker Component -->
                  <DatePicker :value="date" :clearable="false" @change="changeDate">
                    <template v-slot:input>
                      <div class="calender-class">
                        <div class="circle" style="position: relative; "> <i class="fa fa-calendar-o color-text" style="font-size:16px;position: relative;top:6px;left: 2px;"></i></div>
                        <span style="cursor: pointer; top:1px;position: relative;color: var(--color-metal-grey)">{{displayDate}}</span>
                      </div>
                    </template>
                    <i slot="icon-calendar"></i>
                  </DatePicker>
                  <!-- Next Day Button -->
                  <button class="btn btn-icon m-0 ml-1 mr-1 mt-2" @click="nextDay">
                    <i class="fa fa-angle-right color-text" style="font-size: 2.4rem; top: 14px;" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="table-header" :style="`--colW: ${colW}; --cellW: ${colW / 4}; --headerH: ${headerH}`">
            <div class="label-wrapper">
              <div class="table-header-first" >
                <div class="shop-table-name text-while" style="margin-top: 0.6rem;">テーブル名</div>
              </div>
            </div>
            <div class="table-wrapper" :style="{transition: '200ms linear 50ms'}" >
              <div class="table-content">
                <div id="table-header" class="table-header sticky-hour" :style="{ height: headerH, lineHeight: headerH+'px', top: '140px'}">
                  <div v-for="hour, i in timeTableHours" :key="i" class="hour ">{{hour % 24}}:00</div>
                </div>
              </div>
            </div>
          </div>


          <div class="time-table-container" >
            <div class="timetable" :style="`--colW: ${colW}; --cellW: ${colW / 4}; --headerH: ${headerH}`">
              <div class="label-wrapper">
                <div v-for="table, i in tables" :key="i" id="shop-table-name" class="table-row" :style="{ height: rowH + rowH*(table.overlapLevel || 0) }">
                  <div class="shop-table-name" style="cursor: pointer;" @click="openReservationBlockModal(table)">
                    <span class="table-name">{{table.name}}</span> <span class="table-no">{{table.number_of_people_min && table.number_of_people_min}} - {{table.number_of_people_max && table.number_of_people_max}} 名席</span>
                  </div>
                </div>
              </div>
              <div class="scrollbar-space"></div>
              <div class="data-wrapper" id="reservation-data-wrapper" ref="dataWrapper" >
                <div class="scroll-wrapper" >

                  <!--              <div :style="{ height: headerH}"></div>-->
                  <div class="data-scroll-wrapper" ref="secondDataWrapper" id="data-scroll-wrapper">
                    <div v-for="table, iTable in tables" :key="iTable" class="table-row" :style="{ height: rowH + rowH*(table.overlapLevel || 0) }">
                      <template v-for="hour, i in timeTableHours">
                        <div v-for="minute in [0, 15, 30, 45]" :key="i + minute / 60" :data-index="hour + ':' + String(minute).padStart(2, '0')"
                             :class="[cellClass(hour, minute), {'busy': isBlockedTime(hour, minute, timeTable[iTable].reservations)}]">
                        </div>
                      </template>
                    </div>
                  </div>
                  <!----------------------------------Reservation start 1---------------------------------->


                  <div class="reservation-scroller" :style="{top: '0px', width: getWidth(hours, minutes, staying_minutes), left: getX(hours, minutes)}">
                    <div v-for="(table, iTable) in tables" class="reservation-main-container reservation-time-container opacity-unset"
                         :style="{
                  height: rowH + rowH*(table.overlapLevel || 0),
                  width: getWidth(hours, minutes, staying_minutes),
                  position: 'relative'
              }">


                      <div class="reservation-time reservation"
                           :class="'expandabletime'+table.id"
                           @click="toggleSeat(table.id)"
                           :style="{
                  height: getHeight(rowH, table.overlapLevel),
                  width: isSelected(table.id) ? getInsideWidth(hours, minutes, staying_minutes) : getInsideWidth(hours, minutes, staying_minutes),
                  backgroundColor: '--sub-color-opacity10',
                  border: isSelected(table.id) ? '3px solid var(--sub-color)' : '',
              }">
                        <div v-show="isSelected(table.id)"
                             :class="[ 'expandableinsidetime'+table.id , { 'selected': isSelected(table.id) }]"
                             :style="{
                  height: getHeight(rowH, table.overlapLevel),
                  width: getInsideWidth(hours, minutes, staying_minutes),
                  backgroundColor: (isSelected(table.id) ? 'var(--sub-color-opacity)' : '--sub-color-opacity10'),
              }">
                        </div>
                        <template v-if="isSelected(table.id)" style="pointer-events: none;">
                          <span class="centered-text" style="font-size: 18px;">登録</span>
                        </template>
                      </div>
                      <div class="icon-container" :class="['expandablelefttimediv'+table.id]" :data-index="table.id" draggable="true" @dragenter.prevent @dragover.prevent @dragstart="handleDragStart" :style="{ height: getIconHeight(rowH, table.overlapLevel), left: '6px', width:'24px' }">
                        <div style="position: absolute; top: 50%; transform: translateY(-50%); width: 15px; height: 15px;left:2px;">
                          <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.10938 1L0.998264 4.11111L4.10937 7.22222" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.8906 7.22266L15.0017 4.11155L11.8906 1.00043" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <line x1="1" y1="3.88867" x2="15" y2="3.88867" stroke="white" stroke-width="2"/>
                          </svg>
                        </div>
                      </div>
                      <div class="icon-container" :class="['expandabletimediv'+table.id]" :data-index="table.id" draggable="true" @dragenter.prevent @dragover.prevent @dragstart="handleDragStart" :style="{ height: getIconHeight(rowH, table.overlapLevel), right: '4px' }">
                        <div style="position: absolute; top: 50%; transform: translateY(-50%); width: 15px; height: 15px; right: 5px;">
                          <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.10938 1L0.998264 4.11111L4.10937 7.22222" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.8906 7.22266L15.0017 4.11155L11.8906 1.00043" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <line x1="1" y1="3.88867" x2="15" y2="3.88867" stroke="white" stroke-width="2"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>





                  <!----------------------------------Reservation start---------------------------------->
                  <div>
                    <template v-for="table, i in tables">
                      <template v-for="r in timeTable[i].reservations" style="padding:2px;" >
                        <div class="old-reservation opacity-unset reservation-main-container"
                             v-show="!r.hide && getReservationWidth(r.start, r.stay_minutes) > 0"
                             :class="[ r.overlap ? 'overlap': '', r.state] "
                             :key="`${i}.${r.id}`"
                             :style="{
                                  height: rowH - 2 + rowH*(r.expandLevel || 0),
                                  top: rowH*table.offsetY + rowH*(r.overlapLevel || 0) + 1 - rowH*(r.expandLevel || 0),
                                  width: getReservationWidth(r.start, r.stay_minutes),
                                  left: getReservationX(r.start),
                                  padding: '4px'
                                }"
                             :data-id="r.id"
                        >
                          <div class="reservation-container" v-if="r.state != 'busy'">
                            <div class="reservation-content" >
                              <template v-if="r.state != 'busy'">
                                <template v-if="r.stay_minutes >= 30">
                                  <div class="number-of-people">{{r.start | filterTime}} - {{ r.end | filterTime}} {{r.number_of_people}}名</div>
                                  <div class="full-name"
                                       :class="{ 'merge-table': r.customer.count_reservation }"
                                  >
                                    {{ r.type === 'pos' ? '無' : `${r.last_name} ${r.first_name} 様` }}
                                  </div>
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                  <div class="current-time-line" :style="{ left: nowX }"></div>

                  <!------------15-02-1994--------------------Reservation end---------------------------------->
                </div>
              </div>
            </div>
            <CloseValidationModal v-if="showCloseValidationModal"
                                  @close="closeModal"
                                  :date="date"
                                  :title="'sdfj'"
                                  :messages="'dsjh'"/>
            <EditValidationModal
                v-if="showValidationModal"
                :title="title"
                :type="errorType"
                :messages="validationErrorMessages"
                @close="showValidationModal = false"
                @save="saveReservation"
            />
          </div>

          <div class="reservation-footer-nav">
            <button class="btn btn-lg btn-primary float-right mr-10 footer-button" @click="updateReservationDate">
              確定
            </button>
          </div>

        </div>

      </div>
    </transition>

  </div>

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
moment.locale("ja", { weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"] });
import 'vue2-datepicker/locale/ja';

import Http from "../../../shared/http";
import stores from "../../../src/stores";
import Loading from '../../components/Loading';
import DatePicker from 'vue2-datepicker';
import {decode_data, get_url_parameter} from "../../../shared/util";
import ValidationModal from "../../../../../javascript/packs/src/components/ValidationModal.vue";
import CloseValidationModal from "../../../../../javascript/packs/shop_manager/time_table/components/CloseValidationModal.vue";
import EditValidationModal from "../../../../../javascript/packs/src/components/EditValidationModal.vue";
import {validationMessages} from "../../validator/reservationValidationMessages";

export default defineComponent({
  components: {
    EditValidationModal,
    CloseValidationModal,
    ValidationModal,
    DatePicker,
    Loading,
  },
  props:{

  },
  filters:{
    filterTime(value){
      return moment(value).format('HH:mm')
    }
  },
  computed: {
    selectedTableNames() {
      return this.selected_seats
          .map(seatId => this.tables.find(table => table.id === seatId)?.name)
          .filter(Boolean);
    },
    prevTableNames() {
      return this.reservation.shop_table_ids
          .map(seatId => this.tables.find(table => table.id === seatId)?.name)
          .filter(Boolean);
    },
    checkReservation(){
      const time_moment = moment(new Date(this.date).setHours(Number(this.hours), Number(this.minutes), 0));
      const new_time = time_moment.format('YYYY/MM/DD HH:mm');
      return (this.arraysAreEqual(this.selected_seats,this.reservation.shop_table_ids)) && (new_time === this.reservation.start_time) && (this.staying_minutes === this.reservation.stay_minutes)
    },
    workStart() {
      const workStart = this.workingTimes[0]?.start_time;
      return moment(workStart).set({minute: 0, second: 0, millisecond: 0});
    },
    workEnd() {
      const workEnd = this.workingTimes[this.workingTimes.length - 1]?.end_time;
      const end = moment(workEnd).set({minute: 0, second: 0, millisecond: 0});
      if (moment(workEnd).minute() > 0) end.add(1, 'hour');
      return end;
    },
    timeTableStart() {
      const reservations = this.timeTable.map(t => t.reservations).flat();
      let minReservationStart = reservations.reduce((min, r) => min < r.start ? min : r.start, null);
      minReservationStart = minReservationStart ? moment(minReservationStart) : this.workStart;
      return moment.min([this.workStart, minReservationStart]);
    },
    timeTableEnd() {
      const reservations = this.timeTable.map(t => t.reservations).flat();
      let maxReservationEnd = reservations.reduce((max, r) => max > r.end ? max : r.end, null);
      maxReservationEnd = maxReservationEnd ? moment(maxReservationEnd) : this.workEnd;
      return moment.max([this.workEnd, maxReservationEnd]);
    },
    timeTableHours() {
      try {
        if (this.workingTimes.length < 1) return [];
        let hours = Math.round(moment.duration(this.timeTableEnd.diff(this.timeTableStart)).asHours());
        const startHour = this.timeTableStart.hour();
        return new Array(hours).fill(0).map((_, i) => startHour + i);
      } catch {
        this.reloadComponent();
      }
    },
    tables() {
      return this.timeTable.map(t => t.table);
    },
    displayDate() {
      this.updateNowX();
      return moment(this.date).format("YYYY-MM-DD (ddd)");
    },
  },
  data() {
    return {

      headerH: 40,
      rowH: 80,
      colW: 120,
      nowX: -10,
      headerTop: 0,
      initialWidth: 0,
      initialTimeDivRight: 0,
      startX: 0,
      startY: 0,
      title: '',
      showValidationModal:false,
      showCloseValidationModal:false,
      initialScrollLeft: 0,
      defaultWidth: 238,
      staying_minutes: 120,
      loading: true,
      reservation: decode_data("#data-reservation"),
      reservations: [],
      workingTimes: [],
      timeTable: [],
      initialTimeDivLeft:0,
      hours: 0,
      selected_seats:[],
      minutes: 0,
      errorType: null,
      validationErrorMessages:[],
      prev_staying_minutes: 0,
      date: new Date(),
    };
  },
  created(){
    this.date = new Date(this.reservation.start_time);
    this.selected_seats = [...this.reservation.shop_table_ids];
    this.getTimeTableData(this.date);
  },
  beforeDestroy () {
    clearInterval(this.timerNowX);
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    // this.setTableHeaderPosition();
    // window.addEventListener('scroll', this.setTableHeaderPosition);
    this.loading = true

    await this.updateNowX()

    const date = new Date(this.reservation.start_time);
    this.hours = date.getHours().toString().padStart(2, '0');
    this.minutes = date.getMinutes().toString().padStart(2, '0');
    this.staying_minutes = this.reservation.stay_minutes
    this.prev_staying_minutes = this.reservation.stay_minutes
    await setTimeout(() => {
      this.initializeExpandableTimeDivs();
      this.scrollToNow();

    }, 200);  // Adjust this delay based on how long it might take to render
    this.loading = false
  },
  watch: {
    selected_seats() {
      this.$nextTick(() => {
        this.initializeExpandableTimeDivs();
      });

    },
    workingTimes(){
      this.$nextTick(() => {
        this.loading = true;
        this.updateNowX();
        this.initializeExpandableTimeDivs();
        this.scrollToNow();
        this.loading = false;

      });

    }
  },
  methods: {
    syncTableHeaderScroll() {
      const scrollDemo = document.querySelector("#reservation-data-wrapper");
      const data1 = document.getElementsByClassName('table-header')[0];

      if (data1) {
        data1.scrollLeft = scrollDemo.scrollLeft;
      }
    },
    syncTableHeaderToScroll() {
      const scrollDemo = document.getElementsByClassName("table-header")[0];

      const data1 = document.getElementById('reservation-data-wrapper');
      if (data1) {
        data1.scrollLeft = scrollDemo.scrollLeft;
      }
    },
    scrollToNow() {
      const data1 = document.getElementsByClassName('table-header')[0];
      const scrollDemo = document.querySelector("#reservation-data-wrapper");
      scrollDemo.addEventListener('scroll', this.syncTableHeaderScroll);
      scrollDemo.addEventListener('wheel', this.syncTableHeaderScroll);

      data1.addEventListener('scroll', this.syncTableHeaderToScroll);
      data1.addEventListener('wheel', this.syncTableHeaderToScroll);


      if (data1) {
        const now = this.getX(this.hours, this.minutes);
        data1.scrollTo(now - 60, 0);
      }
    },
    arraysAreEqual(arr1, arr2) {
      // Check if lengths are different
      if (arr1.length !== arr2.length) {
        return false;
      }
      // Compare elements
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
      return true
    },
    handleScroll() {

      const top = this.$refs.dataWrapper.getBoundingClientRect().top * -1
      this.headerTop = (top+140) < 0 ? 0 : (top + 140)
    },
    initializeExpandableTimeDivs() {
      this.loading = true;

      this.selected_seats.forEach(seat => {
        const boxes = document.querySelectorAll(".expandabletimediv"+seat);
        boxes.forEach(box => {
          box.style.display = 'block';
        });
        const boxes1 = document.querySelectorAll(".expandablelefttimediv"+seat);
        boxes1.forEach(box => {
          box.style.display = 'block';
        });
        const timeDivElements1 = document.getElementsByClassName('expandablelefttimediv' + seat);
        if (timeDivElements1.length > 0) {
          timeDivElements1[0].addEventListener('mousedown', this.startLeftDrag);
          timeDivElements1[0].addEventListener('touchstart', this.startLeftDrag);
        }

        const timeDivElements = document.getElementsByClassName('expandabletimediv' + seat);
        if (timeDivElements.length > 0) {
          timeDivElements[0].addEventListener('mousedown', this.startDrag);
          timeDivElements[0].addEventListener('touchstart', this.startDrag);
        }
      });
      this.loading = false;

    },
    closeModal(){
      this.showCloseValidationModal = false;
    },
    startDrag(event) {
      event.preventDefault()
      let x = this.checkdevice()? event.changedTouches[0].clientX : event.clientX //event for touch or pointer
      let y = this.checkdevice()? event.changedTouches[0].clientY : event.clientY
      const toIndexElement = event.target.closest('[data-index]').getAttribute('data-index');
      const ele1 =  document.getElementsByClassName('expandableTime' + toIndexElement)[0]
      const lastdivElements = document.getElementsByClassName('expandabletimediv' + toIndexElement)[0]; // expanding width of the all related reservations
      // initializing the width of all reservation
      this.initialWidth = parseInt(ele1.style.width.replace('px', ''));
      this.initialTimeDivRight = parseInt(lastdivElements.style.right.replace('px', ''));
      document.addEventListener('touchmove', this.handleDrag, {passive:false});
      document.addEventListener('dragstart', this.handleDrag, {passive:false});
      document.addEventListener('mousemove', this.handleDrag, {passive:false});
      document.addEventListener('dragmove', this.handleDrag, {passive:false});
      document.addEventListener('mouseup', this.handleDrop, {passive:false});
      document.addEventListener('touchend', this.handleDrop, {passive:false});
      document.addEventListener('dragend', this.handleDrop, {passive:false});
      this.startX = this.checkdevice()? event.changedTouches[0].clientX : event.clientX;
      this.startY = this.checkdevice()? event.changedTouches[0].clientY : event.clientY;
      const scrollDemo = document.querySelector("#reservation-data-wrapper");
      // initilizing table warpper scroll pos
      this.initialScrollLeft = scrollDemo.scrollLeft
    },
    startLeftDrag(event) {
      event.preventDefault()
      // let x = this.checkdevice()? event.changedTouches[0].clientX : event.clientX //event for touch or pointer
      // let y = this.checkdevice()? event.changedTouches[0].clientY : event.clientY
      const toIndexElement = event.target.closest('[data-index]').getAttribute('data-index');
      const ele1 =  document.getElementsByClassName('expandableTime' + toIndexElement)[0]
      const lastdivElements = document.getElementsByClassName('expandablelefttimediv' + toIndexElement)[0]; // expanding width of the all related reservations
      // initializing the width of all reservation
      const scrollEle = document.getElementsByClassName('reservation-scroller')[0];
      this.initialWidth = parseInt(ele1.style.width.replace('px', ''));
      this.initialTimeDivLeft = parseInt(scrollEle.style.left.replace('px', ''));
      document.addEventListener('touchmove', this.handleLeftDrag, {passive:false});
      document.addEventListener('dragstart', this.handleLeftDrag, {passive:false});
      document.addEventListener('mousemove', this.handleLeftDrag, {passive:false});
      document.addEventListener('dragmove', this.handleLeftDrag, {passive:false});
      document.addEventListener('mouseup', this.handleLeftDrop, {passive:false});
      document.addEventListener('touchend', this.handleLeftDrop, {passive:false});
      document.addEventListener('dragend', this.handleLeftDrop, {passive:false});
      this.startX = this.checkdevice()? event.changedTouches[0].clientX : event.clientX;
      this.startY = this.checkdevice()? event.changedTouches[0].clientY : event.clientY;
      const scrollDemo = document.querySelector("#reservation-data-wrapper");
      // initilizing table warpper scroll pos
      this.initialScrollLeft = scrollDemo.scrollLeft

    },
    handleLeftDrag(event){
      event.preventDefault()
      let data = document.getElementById('reservation-data-wrapper');
      let newScrollLeft = data.scrollLeft - this.initialScrollLeft;

      const clientX = this.checkdevice() ? event.changedTouches[0].clientX : event.clientX;
      const relativeX = clientX + newScrollLeft;
      const newWidth = this.initialWidth + (this.startX - relativeX);
      const newTimeLeft = this.initialTimeDivLeft - (this.startX - relativeX);
      const reservation_scroller = document.getElementsByClassName('reservation-scroller')[0];
      // if((newWidth + newScrollLeft)>this.defaultWidth) {
      reservation_scroller.style.width = (newWidth+8 ) + 'px';
      reservation_scroller.style.left = newTimeLeft + 'px'

      for (let i = 0; i < this.selected_seats.length; i++) {
        const divElements = document.getElementsByClassName('expandabletime' + this.selected_seats[i]); // expanding width of the all related reservations
        const currentElement = divElements[0];
        currentElement.style.width = (newWidth ) + 'px'; // Set the width to your desired value
        const expandableInsideTime = document.getElementsByClassName('expandableinsidetime' + this.selected_seats[i]); // expanding width of the all related reservations
        expandableInsideTime[0].style.width = (newWidth ) + 'px'; // Set the width to your desired value
      }
      document.querySelectorAll('.reservation-time-container').forEach(element => {
        element.style.width = (newWidth +8 ) + 'px' ; // Set the desired width
      });      // // get current position on scrolling
      document.querySelectorAll('.reservation-time').forEach(element => {
        element.style.width = (newWidth) + 'px' ; // Set the desired width
      });
      const newX = this.checkdevice()? event.changedTouches[0].clientX : event.clientX
      // scroll to new position after dragging
      const data1 = document.getElementsByClassName('table-header')[0];
      data.scrollTo(  newX- this.startX + this.initialScrollLeft +8, this.startY)

      if (data1) {
        data1.scrollTo( newX- this.startX + this.initialScrollLeft +8, this.startY);
      }
      let divWorking = document.querySelectorAll(".expandableinsidetime"+this.selected_seats[0])[0]
// setting resevation to display for clicking
      let boxes = divWorking.getBoundingClientRect();
      let w = boxes.width;
      const expanded_boxes = Math.floor(w/30) +1
      this.staying_minutes = expanded_boxes * 15

    },

    handleDrag(event){
      event.preventDefault()
      let data = document.getElementById('reservation-data-wrapper');
      let newScrollLeft = data.scrollLeft - this.initialScrollLeft

      const clientX = this.checkdevice() ? event.changedTouches[0].clientX : event.clientX;
      const relativeX = clientX + newScrollLeft;
      const newWidth = this.initialWidth + (relativeX - this.startX );
      const newTimeRight = this.initialTimeDivLeft - (relativeX - this.startX);
      // const newWidth = this.initialWidth  + ((this.checkdevice()? event.changedTouches[0].clientX : event.clientX)- this.startX);
      // const newTimeRight = this.initialTimeDivRight  - ((this.checkdevice()? event.changedTouches[0].clientX : event.clientX)-this.startX);
      const reservation_scroller = document.getElementsByClassName('reservation-scroller')[0];
      const data1 = document.getElementsByClassName('table-header')[0];


      if((newWidth + newScrollLeft)>this.defaultWidth) {
        reservation_scroller.style.width = (newWidth + 8) + 'px';
      }
      else{
        reservation_scroller.style.width =this.defaultWidth + 'px'
      }
      // expanding reservation with the cursor position


      document.querySelectorAll('.reservation-time-container').forEach(element => {
        element.style.width = (newWidth+8 ) + 'px' ; // Set the desired width
      });
      document.querySelectorAll('.reservation-time').forEach(element => {
        element.style.width = (newWidth) + 'px' ; // Set the desired width
      });
      for (let i = 0; i < this.selected_seats.length; i++) {
        const divElements = document.getElementsByClassName('expandabletime' + this.selected_seats[i]); // expanding width of the all related reservations
        const currentElement = divElements[0];
        const lastdivElements = document.getElementsByClassName('expandabletimediv' + this.selected_seats[i]); // expanding width of the all related reservations
        lastdivElements[0].style.right = '4px'
        currentElement.style.width = (newWidth) + 'px'; // Set the width to your desired value
        const expandableInsideTime = document.getElementsByClassName('expandableinsidetime' + this.selected_seats[i]); // expanding width of the all related reservations
        expandableInsideTime[0].style.width = (newWidth) + 'px'; // Set the width to your desired value
      }
      // // get current position on scrolling
      const newX = this.checkdevice()? event.changedTouches[0].clientX : event.clientX
      // scroll to new position after dragging
      data.scrollTo(  newX- this.startX + this.initialScrollLeft +8, this.startY)

      if (data1) {
        const now = this.getX(this.hours, this.minutes);
        data1.scrollTo( newX- this.startX + this.initialScrollLeft +8, this.startY);
      }
      let divWorking = document.querySelectorAll(".expandableinsidetime"+this.selected_seats[0])[0]
// setting resevation to display for clicking
      let boxes = divWorking.getBoundingClientRect();
      let w = boxes.width;
      const expanded_boxes = Math.floor(w/30) +1
      this.staying_minutes = expanded_boxes * 15
      // this.staying_minutes = staying_minutes


    },
    checkdevice() {
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          ||
          (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    getTime(dateString) {
      const date = new Date(dateString);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    changeDate(date) {
      this.date = date;
      this.getTimeTableData(date);
      // const dateString = moment(date).format('YYYY-MM-DD');
      // const url = new URL(window.location.href);
      // url.searchParams.set('date', dateString);
      // window.history.pushState({}, '', url);
    },
    prevDay() {
      const timestamp = new Date(this.date.getTime() - 24 * 60 * 60 * 1000);
      this.changeDate(timestamp);
    },
    nextDay() {
      const timestamp = new Date(this.date.getTime() + 24 * 60 * 60 * 1000);
      this.changeDate(timestamp);
    },
    hasMorePeople(){
      const selectedSeats = this.selected_seats
      const tables = this.timeTable.map(t => t.table)
      const selected_number_of_people = (this.reservation.number_of_adults!=='' ? Number(this.reservation.number_of_adults) : 0) + (this.reservation.number_of_children!=='' ? Number(this.reservation.number_of_children) : 0)
      let max_people_in_table = 0
      tables.some((t) => {
        if(selectedSeats.includes(t.id)){
          max_people_in_table = t.number_of_people_max + max_people_in_table
        }
      });
      return selected_number_of_people > max_people_in_table;
    },
    hasExistingReservation(){
      let startTime = `${this.hours}:${this.minutes}`
      let newReservationDate = this.date
      let startDateTime = this.getDateTime(newReservationDate,startTime)
      let timeTable = this.timeTable
      const selectedSeats = this.selected_seats
      const seatsWithExistingReservation = this.filterTablesWithNoExistingReservation(startDateTime,timeTable)
      return this.hasMatchingTableId(seatsWithExistingReservation, selectedSeats)
    },
    filterTablesWithNoExistingReservation(startTime,tables) {
      let newReservationDateTime = this.possibleReservationTime(startTime)
      return tables.filter(element => {
        return element.reservations.some(reservation => {
          const reservationTimeStart = moment(reservation.start.replace(/(\.\d+)?([+-]\d{2}:\d{2})$/, ''))
          const reservationTimeEnd = moment(reservation.end.replace(/(\.\d+)?([+-]\d{2}:\d{2})$/, ''))
          const newReservationTimeStart = moment(newReservationDateTime.startTime)
          const newReservationTimeEnd = moment(newReservationDateTime.endTime)
          return this.isInExistingReservationTime(newReservationTimeStart,newReservationTimeEnd,reservationTimeStart,reservationTimeEnd)
        });
      });
    },
    hasMatchingTableId(existingTables, selectedSeats) {
      return existingTables.some(seat => selectedSeats.includes(seat.table.id));
    },
    possibleReservationTime(startTime){
      let dateToChange=new Date(startTime)
      const stayMinutes = this.stay_minutes
      return {
        startTime: startTime,
        endTime: new Date(dateToChange.setMinutes(dateToChange.getMinutes() + stayMinutes))
      }
    },
    getDateTime(date,time){
      let dateTimeString = `${date}T${time}`;
      return new Date(dateTimeString);

    },
    isInExistingReservationTime(newReservationStart,newReservationEnd,reservationStart,reservationEnd){
      return (
          newReservationStart.isSame(reservationStart) ||  newReservationEnd.isSame(reservationEnd) ||
          (newReservationStart.isBefore(reservationEnd) && newReservationStart.isAfter(reservationStart)) ||
          (newReservationEnd.isAfter(reservationStart) && newReservationEnd.isBefore(reservationEnd))
      );
    },
    async getTimeTableData(date) {
      this.loading = true;
      const date_param = moment(date).format("YYYY-MM-DD");
      const slug = document.getElementById("slug").value == "" ? "" : `/${document.getElementById("slug").value}`;

      try {
        const response = await Http.get(`${slug}/shop_manager/time_table/time_table_data`, {
          type: "day",
          date: date_param,
        });
        const data = response.data[date_param];
        this.date = date;
        this.workingTimes = data.working_times;
        let timeTable = data.time_table.map(table => {
          return {
            ...table,
            reservations: table.reservations.filter(reservation => reservation.id !== this.reservation.id)
          };
        });
        timeTable = this.countTableMergeNo(timeTable)
        timeTable = this.setOverlapLevel(timeTable)
        timeTable = this.expandNearMergeTable(timeTable)
        this.timeTable = timeTable;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    reloadComponent() {
      this.$emit("reload");
    },
    async openReservationBlockModal(table) {
      await this.getReservations(table.id);

      this.workingTime = {
        start_time: this.workStart.format('YYYY-MM-DD HH:mm'),
        end_time: this.workEnd.format('YYYY-MM-DD HH:mm'),
      };

      this.editReservation = {
        shop_table_ids: table.id ? [table.id] : [],
        start_time: this.workStart.format('YYYY-MM-DD HH:mm'),
        end_time: this.workEnd.format('YYYY-MM-DD HH:mm'),
        start_date: this.workStart
      };
      this.showModalReservationBlock = true;
      this.showReservationModal = false;
      this.showColorSelectModal = false;
      this.showReservationColor = false;
    },

    toggleSeat(tableId) {
      const index = this.selected_seats.indexOf(tableId);
      if (index !== -1) {
        const boxes = document.querySelectorAll(".expandabletimediv"+tableId);
        boxes.forEach(box => {
          box.style.display = 'none';
        });
        const boxes2 = document.querySelectorAll(".expandablelefttimediv"+tableId);
        boxes2.forEach(box => {
          box.style.display = 'none';
        });
        const timeDivElements = document.getElementsByClassName('expandabletimediv' + tableId);// pushing dragging element to left
        timeDivElements[0].removeEventListener('mousedown', this.startDrag);
        timeDivElements[0].removeEventListener('touchstart', this.startDrag);
        const timeDivElements2 = document.getElementsByClassName('expandablelefttimediv' + tableId);// pushing dragging element to left
        timeDivElements2[0].removeEventListener('mousedown', this.startLeftDrag);
        timeDivElements2[0].removeEventListener('touchstart', this.startLeftDrag);
        this.selected_seats.splice(index, 1);

      }
      else{
        const boxes = document.querySelectorAll(".expandabletimediv"+tableId);
        boxes.forEach(box => {
          box.style.display = 'block';
        });
        const boxes2 = document.querySelectorAll(".expandablelefttimediv"+tableId);
        boxes2.forEach(box => {
          box.style.display = 'block';
        });
        const timeDivElements2 = document.getElementsByClassName('expandablelefttimediv' + tableId);// pushing dragging element to left
        timeDivElements2[0].addEventListener('mousedown', this.startLeftDrag);
        timeDivElements2[0].addEventListener('touchstart', this.startLeftDrag);
        const timeDivElements = document.getElementsByClassName('expandabletimediv' + tableId);// pushing dragging element to left
        timeDivElements[0].addEventListener('mousedown', this.startDrag);
        timeDivElements[0].addEventListener('touchstart', this.startDrag);
        this.selected_seats.push(tableId);
      }


    },

    getReservationWidth(start, stay_minutes) {
      const start_moment = moment(start)
      const end_moment = moment(start).add(stay_minutes, 'minutes')
      if (start_moment.isBefore(this.timeTableStart)) {
        stay_minutes = stay_minutes - moment(this.timeTableStart).diff(start_moment, 'minutes')
      }
      if (end_moment.isAfter(this.timeTableEnd)) {
        stay_minutes = stay_minutes - end_moment.diff(moment(this.timeTableEnd), 'minutes')
      }
      return stay_minutes / 60 * this.colW - 1
    },
    async updateReservationDate() {
      if(this.checkReservation){
        const slug = document.getElementById("slug").value == "" ? "" : `/${document.getElementById("slug").value}`;

        const formattedDate = moment(this.date).format('YYYY-MM-DD');
        window.location.href = `${slug}/shop_manager/time_table?date=${formattedDate}`;
      }
      else{
        const previous_time = moment(this.reservation.start_time).format('YYYY/MM/DD HH:mm') + ' ~ ' + moment(this.reservation.end_time).format('HH:mm')
        const new_start_time =  moment(new Date(this.date).setHours(Number(this.hours), Number(this.minutes), 0));
        const new_end_time = moment(new Date(this.date).setHours(Number(this.hours), Number(this.minutes), 0)).add(this.staying_minutes, 'minutes');
        const new_time = new_start_time.format('YYYY/MM/DD HH:mm') + ' ~ ' + new_end_time.format('HH:mm')
        const tableChange = this.arraysAreEqual(this.prevTableNames,this.selectedTableNames)
        this.validationErrorMessages = []
        this.errorType = null
        if(this.selected_seats.length<1){
          this.validationErrorMessages.push(validationMessages.NO_SELECTED_SEATS)
        }
        else if(this.hasMorePeople()){
          this.validationErrorMessages.push(validationMessages.MAX_PEOPLE_EXCEEDED)
        }
        if( this.hasExistingReservation()===true){
          this.validationErrorMessages.push(validationMessages.SELECTED_TABLE_WITH_EXISTING_RESERVATION)
        }
        if(this.validationErrorMessages.length>0){
          this.title='確認';
        }
        else{
          this.errorType = 'change'
          this.title="予約を変更しますか？"
          let message = ''
          if(tableChange){
            message = `<span>
                          ${this.reservation.last_name} ${this.reservation.first_name} 様 ${this.reservation.number_of_people}名 </br>
                            ${previous_time}
                              </br>
                          ↓
                          </br>
                          ${new_time}
                         </span>`
          }
          else{
            message = `<span>
                        ${this.reservation.last_name} ${this.reservation.first_name} 様 ${this.reservation.number_of_people}名 </br>
                        テーブル : ${this.prevTableNames}</br>
                          ${previous_time}
                            </br>
                        ↓
                        </br>
                        テーブル : ${this.selectedTableNames}</br>
                        ${new_time}
                       </span>`
          }

          this.validationErrorMessages.push(message)
        }
        this.showValidationModal = true

      }

    },
    async saveReservation(){
      const slug = document.getElementById("slug").value == "" ? "" : `/${document.getElementById("slug").value}`;
      const path = `${slug}/shop_manager/time_table/${this.reservation.id}`;
      const new_start_time =  moment(new Date(this.date).setHours(Number(this.hours), Number(this.minutes), 0));
      const new_time = new_start_time.format('YYYY/MM/DD HH:mm');


      await Http.post(path, {
        reservation: {
          shop_table_ids: this.selected_seats,
          start_time: new_time,
          stay_minutes: this.staying_minutes,
          is_seat_change_only:true
        },
      })
          .then(response => {
            this.$emit("loading", false);
            const formattedDate = moment(this.date).format('YYYY-MM-DD');
            window.location.href = `${slug}/shop_manager/time_table?date=${formattedDate}`;
          })
          .catch(error => {
            this.$emit("loading", false);
            this.validationErrorMessages.push(error.response.data)
            this.showValidationModal = true
          });

    },
    countTableMergeNo(timeTable) {
      const mergeIds = []
      timeTable.forEach((t, i) => {
        t.reservations.forEach((r, j) => {
          if (r.is_table_merged) {
            if (!mergeIds.includes(r.id)) mergeIds.push(r.id)
            r.mergeNo = mergeIds.indexOf(r.id) + 1
          }
        })
      })
      return timeTable
    },
    //
    // 予約が重なっている場合に、重なりレベルを計算する
    //
    setOverlapLevel(timeTable) {
      timeTable.forEach((t, i) => {
        const tableReservations = t.reservations
        let tableOverlapLevel = 0

        tableReservations.forEach((r, j) => {
          tableReservations.filter((r2, j2) => {
            if (r2.state == 'busy' || r.state == 'busy') return false;

            const overlap = j2 > j && r2.start < r.end && r2.end > r.start
            if (overlap) {
              this.findAllSameReservation(timeTable, r.id).forEach((r) => {
                r.overlap = true
              })
              this.findAllSameReservation(timeTable, r2.id).forEach((r) => {
                r.overlap = true
              })
              r.overlapLevel ||= 0
              r2.overlapLevel = r.overlapLevel + 1

              if (tableOverlapLevel < r2.overlapLevel) {
                tableOverlapLevel = r2.overlapLevel
              }
            }
            return overlap;
          })

          if (r.type === 'pos') {
            this.findAllSameReservation(timeTable, r.id).forEach((r3) => {
              r3.mergeable_reservations ||= []
              r3.mergeable_reservations = r3.mergeable_reservations.concat(tableReservations.filter((r2, j2) => {
                return j2 != j && r2.start < r.end && r2.end > r.start
                    && r2.type != 'pos'
                    && r2.state != 'busy'
                    && r2.pos_order_id == null
              }))
            })
          }
        })
        timeTable[i].table.overlapLevel = tableOverlapLevel
        timeTable[i].table.offsetY ||= i
        if (i + 1 < timeTable.length) {
          timeTable[i + 1].table.offsetY = timeTable[i].table.offsetY + 1 + tableOverlapLevel
        }
      })
      return timeTable
    },
    //
    // となりのテーブルと結合して予約された場合は、繋がっているような見せ方にする
    //
    expandNearMergeTable(timeTable) {
      timeTable.forEach((t, i) => {
        if (i < 1) return

        const prevRow = timeTable[i - 1].reservations
        const prevTable = timeTable[i - 1].table
        const thisRow = t.reservations
        const thisTable = t.table
        thisRow.forEach((r, j) => {
          const prevR = prevRow.find((r2) => r2.id === r.id)
          if (prevR) {
            prevR.hide = true
            r.expandLevel = (prevR.expandLevel || 0) + (prevTable.overlapLevel || 0) + 1
          }
        })
      })
      return timeTable
    },
    findAllSameReservation(timeTable, id) {
      const result = []
      timeTable.forEach((t, i) => {
        t.reservations.forEach((r, j) => {
          if (r.id === id) {
            result.push(r)
          }
        })
      })
      return result
    },
    resetLeftData(edited){
      this.staying_minutes = this.prev_staying_minutes
      if(edited) {
        if (this.initialWidth > 0) {
          for (let i = 0; i < this.selected_seats.length; i++) {
            // reservation-scroller
            const divElements = document.getElementsByClassName('expandabletime' + this.selected_seats[i]); // expanding width of the all related reservations
            const currentElement = divElements[0];
            // divElements.style.width = this.initialWidth + 'px'; // Set the width to your desired value
            const lastdivElements = document.getElementsByClassName('expandabletimediv' + this.selected_seats[i]); // expanding width of the all related reservations
            lastdivElements[0].style.right =  '4px'
            currentElement.style.width = this.initialWidth + 'px'; // Set the width to your desired value
            const expandableInsideTime = document.getElementsByClassName('expandableinsidetime' + this.selected_seats[i]); // expanding width of the all related reservations
            expandableInsideTime[0].style.width = this.initialWidth + 'px'; // Set the width to your desired value
          }
        }
        document.querySelectorAll('.reservation-time-container').forEach(element => {
          element.style.width = (this.initialWidth+8 ) + 'px' ; // Set the desired width
        });
        document.querySelectorAll('.reservation-time').forEach(element => {
          element.style.width = (this.initialWidth) + 'px' ; // Set the desired width
        });
        // const reservation_main_container = document.getElementsByClassName('reservation-main-container')

        const reservation_scroller = document.getElementsByClassName('reservation-scroller')[0];
        reservation_scroller.style.width = (this.initialWidth+8 ) + 'px'
        reservation_scroller.style.left = (this.initialTimeDivLeft) + 'px'

      }
      document.removeEventListener('touchmove', this.handleDrag);
      document.removeEventListener('mousemove', this.handleDrag);
      document.removeEventListener('dragmove', this.handleDrag);
      document.removeEventListener('touchmove', this.handleLeftDrag);
      document.removeEventListener('mousemove', this.handleLeftDrag);
      document.removeEventListener('dragmove', this.handleLeftDrag);

      this.startY = 0
      this.startX=0
      this.initialWidth = '232px'
      this.initialScrollLeft = 0
    },
    resetData(edited){
      this.staying_minutes = this.prev_staying_minutes
      if(edited) {
        if (this.initialWidth > 0) {
          for (let i = 0; i < this.selected_seats.length; i++) {
            // reservation-scroller
            const divElements = document.getElementsByClassName('expandabletime' + this.selected_seats[i]); // expanding width of the all related reservations
            const currentElement = divElements[0];
            // divElements.style.width = this.initialWidth + 'px'; // Set the width to your desired value
            const lastdivElements = document.getElementsByClassName('expandabletimediv' + this.selected_seats[i]); // expanding width of the all related reservations
            lastdivElements[0].style.right =  '4px'
            currentElement.style.width = this.initialWidth + 'px'; // Set the width to your desired value
            const expandableInsideTime = document.getElementsByClassName('expandableinsidetime' + this.selected_seats[i]); // expanding width of the all related reservations
            expandableInsideTime[0].style.width = this.initialWidth + 'px'; // Set the width to your desired value
          }
        }
        document.querySelectorAll('.reservation-time-container').forEach(element => {
          element.style.width = (this.initialWidth+8 ) + 'px' ; // Set the desired width
        });
        document.querySelectorAll('.reservation-time').forEach(element => {
          element.style.width = (this.initialWidth) + 'px' ; // Set the desired width
        });
        // const reservation_main_container = document.getElementsByClassName('reservation-main-container')

        const reservation_scroller = document.getElementsByClassName('reservation-scroller')[0];
        reservation_scroller.style.width = (this.initialWidth+8 ) + 'px'
      }
      document.removeEventListener('touchmove', this.handleDrag);
      document.removeEventListener('mousemove', this.handleDrag);
      document.removeEventListener('dragmove', this.handleDrag);
      document.removeEventListener('touchmove', this.handleLeftDrag);
      document.removeEventListener('mousemove', this.handleLeftDrag);
      document.removeEventListener('dragmove', this.handleLeftDrag);

      this.startY = 0
      this.startX=0
      this.initialWidth = '232px'
      this.initialScrollLeft = 0
    },

    handleLeftDrop(event){
      event.preventDefault();

      let divWorking = document.querySelectorAll(".expandableinsidetime"+this.selected_seats[0])[0]

// setting resevation to display for clicking
      let boxes = divWorking.getBoundingClientRect();
      let w = boxes.width;
      const expanded_boxes = Math.floor(w/30) +1
      const staying_minutes = expanded_boxes * 15
      const elements = document.elementsFromPoint(boxes.left , boxes.bottom);
      const ele2 = elements.find(element => element.classList.contains("dropzone"));
      if(ele2 === undefined || ele2 === null){
        event.stopImmediatePropagation()
        this.resetLeftData(true)
        return
      }
      this.hours = parseInt(ele2.dataset.index.split(':')[0])
      this.minutes = parseInt(ele2.dataset.index.split(':')[1])      //checking if the end of expanded reservation is in drop zone

      for (let i = 0; i < this.selected_seats.length; i++) {
        const lastdivElements = document.getElementsByClassName('expandablelefttimediv' + this.selected_seats[i]); // expanding width of the all related reservations
        lastdivElements[0].style.left =  '6px'
      }
      this.staying_minutes = staying_minutes
      this.prev_staying_minutes = this.staying_minutes

      this.removeEvents()

    },

    handleDrop(event){
      event.preventDefault();

      let divWorking = document.querySelectorAll(".expandableinsidetime"+this.selected_seats[0])[0]
// setting resevation to display for clicking
      let boxes = divWorking.getBoundingClientRect();
      let w = boxes.width;
      const expanded_boxes = Math.floor(w/30) +1
      const staying_minutes = expanded_boxes * 15
      const elements = document.elementsFromPoint(boxes.right , boxes.bottom);
      const ele2 = elements.find(element => element.classList.contains("dropzone"));
      //checking if the end of expanded reservation is in drop zone

      if(ele2 === undefined || ele2 === null){
        event.stopImmediatePropagation()
        this.resetData(true)
        return
      }
      for (let i = 0; i < this.selected_seats.length; i++) {
        const lastdivElements = document.getElementsByClassName('expandabletimediv' + this.selected_seats[i]); // expanding width of the all related reservations
        lastdivElements[0].style.right =  '4px'
      }
      this.staying_minutes = staying_minutes
      this.prev_staying_minutes = this.staying_minutes

      // this.$emit('change-stay-minutes', this.staying_minutes)
      this.removeEvents()
      this.updateNowX();

    },
    removeEvents(){
      document.removeEventListener("mousedown", this.startDrag);
      document.removeEventListener('touchstart', this.startDrag);
      document.removeEventListener('touchmove', this.handleDrag);
      document.removeEventListener('mousemove', this.handleDrag);
      document.removeEventListener('dragmove', this.handleDrag);
      document.removeEventListener('mouseup', this.handleDrop);
      document.removeEventListener('touchend', this.handleDrop);
      document.removeEventListener('dragstart', this.handleDrop);
      document.removeEventListener('dragend', this.handleDrop);
      document.removeEventListener("mousedown", this.startLeftDrag);
      document.removeEventListener('touchstart', this.startLeftDrag);
      document.removeEventListener('touchmove', this.handleLeftDrag);
      document.removeEventListener('mousemove', this.handleLeftDrag);
      document.removeEventListener('dragmove', this.handleLeftDrag);
      document.removeEventListener('mouseup', this.handleLeftDrop);
      document.removeEventListener('touchend', this.handleLeftDrop);
      document.removeEventListener('dragstart', this.handleLeftDrop);
      document.removeEventListener('dragend', this.handleLeftDrop);
    },

    handleDragOver(event) {
      event.preventDefault();
    },

    handleDragStart(event) {
      // Prevent the default drag behavior to allow custom dragging
      event.preventDefault();
    },


    getTimeX(start, stay_minutes) {
      const time_moment = moment(start)
      if (this.workingTimes.length < 1) return -1000
      if (time_moment.isBefore(this.timeTableStart)) return 0
      if (time_moment.isAfter(this.timeTableEnd)) return -1000
      const start_moment = moment(start)
      const end_moment = moment(start).add(stay_minutes, 'minutes')
      if (start_moment.isBefore(this.timeTableStart)) {
        stay_minutes = stay_minutes - moment(this.timeTableStart).diff(start_moment, 'minutes')
      }
      if (end_moment.isAfter(this.timeTableEnd)) {
        stay_minutes = stay_minutes - end_moment.diff(moment(this.timeTableEnd), 'minutes')
      }
      return time_moment.diff(moment(this.timeTableStart), 'minutes') / 60 * this.colW + (stay_minutes / 60 * this.colW -1)
    },

    getIconHeight(rowHeight, overlap) {
      return rowHeight + rowHeight * (overlap || 0) - 12
    },

    cellClass(hour, minute) {
      const classes = ['cell', 'dropzone'];
      if (minute === 45) classes.push('end-hour');
      const time = moment(this.date).set({hour, minute});
      if (!this.workingTimes.some(wt => moment(wt.start_time) <= time && time < moment(wt.end_time))) classes.push('outside-business-hour');
      return classes;
    },
    isBlockedTime(hour, minute, tableReservations) {
      const time = moment(this.date).set({hour, minute});
      return tableReservations.filter(r => r.state === 'busy').some(r => moment(r.start) <= time && time < moment(r.end));
    },
    // autoReload() {
    //   if (!this.loading) this.$emit('reload', true)
    // },
    updateNowX() {
      this.loading = true;
      this.nowX = this.getReservationX(new Date());
      let now = this.getX(this.hours, this.minutes);
      this.$nextTick(() => {
        const data = document.getElementById('reservation-data-wrapper');
        data.style.overflowX = 'auto'
        data.scrollTo(now - 60, 0); // Example: scrolls to 100px from the top
        data.addEventListener('scroll', this.handleScroll)
        data.addEventListener('wheel', this.handleScroll);
        const data1 = document.getElementsByClassName('table-header')[0];
        if (data1) {
          const now = this.getX(this.hours, this.minutes);
          data1.scrollTo(now - 60, 0);
        }
      });
      this.loading = false;
    },
    getReservationX(time) {
      const time_moment = moment(time)
      if (this.workingTimes.length < 1) return -1000
      if (time_moment.isBefore(this.timeTableStart)) return 0
      if (time_moment.isAfter(this.timeTableEnd)) return -1000

      return time_moment.diff(moment(this.timeTableStart), 'minutes') / 60 * this.colW
    },
    getWidth(hours, minutes, stay_minutes) {
      const start = moment(new Date(this.date).setHours(Number(hours), Number(minutes), 0));
      const start_moment = moment(start);
      const end_moment = moment(start).add(stay_minutes, 'minutes');

      let adjustedStayMinutes = stay_minutes;

      if (start_moment.isBefore(this.timeTableStart)) {
        adjustedStayMinutes -= this.timeTableStart.diff(start_moment, 'minutes');
      }
      if (end_moment.isAfter(this.timeTableEnd)) {
        adjustedStayMinutes -= end_moment.diff(this.timeTableEnd, 'minutes');
      }

      return (adjustedStayMinutes / 60) * this.colW;
    },
    getHeight(rowHeight, overlap) {
      return rowHeight + rowHeight * (overlap || 0) - 8
    },
    isSelected(tableId) {
      return this.selected_seats.includes(tableId);
      // return false;
    },
    getInsideWidth(hours, minutes, stay_minutes) {
      let start = moment(new Date(this.date).setHours(Number(hours), Number(minutes), 0));
      const start_moment = moment(start)
      const end_moment = moment(start).add(stay_minutes, 'minutes')
      if (start_moment.isBefore(this.timeTableStart)) {
        stay_minutes = stay_minutes - moment(this.timeTableStart).diff(start_moment, 'minutes')
      }
      if (end_moment.isAfter(this.timeTableEnd)) {
        stay_minutes = stay_minutes - end_moment.diff(moment(this.timeTableEnd), 'minutes')
      }
      return (stay_minutes / 60 * this.colW) - 8
    },
    getX(hours, minutes) {
      const time_moment = moment(new Date(this.date).setHours(Number(hours), Number(minutes), 0));
      if (this.workingTimes.length < 1) return -1000
      if (time_moment.isBefore(this.timeTableStart)) return 0
      if (time_moment.isAfter(this.timeTableEnd)) return -1000
      return (time_moment.diff(moment(this.timeTableStart), 'minutes') / 60 * this.colW)
    },
  },
});
</script>

<!--1993 11 07-->

<style lang="scss" scoped>
.wrapper
{
  overflow: hidden;
}
.timetable {
  display: flex;
  background-color: #FFFFFF;
  min-width: 100%;
  min-height: 345px;
  margin: auto;
}

.label-wrapper {
  position: sticky;
  left: 0px;
  z-index: 1999;
  background: #FFFFFF;
  border-right: 1px solid #66615b15;
  box-shadow: 2px 0px 8px 0px #00000010 !important;

  .shop-table-name {
    width: 150px;
    border-right: 1px solid #66615b15;
    color: var(--color-table-name);
    margin-left: 20px;
  }

  #shop-table-name {
    .shop-table-name {
      margin-left: 20px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

    }
  }

  .shop-table-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-main);
  }

  .capacity {
    width: 50px;
  }

  .table-row {
    display: flex;
    align-items: center;
    border-top: 1px solid #66615b59;
  }

  .scrollbar-space {
    height: 14px;
    border-top: 1px solid #66615b15;
  }
}

.timetable-main-container {
  min-width: 100vw;
  max-height: 100dvh;
  min-height: 100dvh;
}

.time-table-container {
  height: 70dvh;
  overflow: scroll;
  margin: auto;
}

.data-wrapper {
  background-color: white;
  flex-grow: 1;

  .hour {
    width: var(--colW);
    text-align: left;
    padding-left: 4px;
    border-right: 1px solid #66615b15;
  }

  .scroll-wrapper {
    position: relative;
    margin-right: 50px;

    display: table;
    border-bottom: 1px solid #66615b15;
  }

  .table-row {
    display: flex;
    border-top: 1px solid #CCCCCC;

    .cell {
      border-right: 1px dashed #DDDDDD;
      width: var(--cellW);

      &:hover {
        background-color: #66615b15;
      }

      &.end-hour {
        border-right: 1px solid #CCCCCC;
      }

      &.outside-business-hour {
        background-color: var(--color-businesshour-background);
      }

      &.busy {
        background-color: var(--color-tt-block-bg);
        opacity: 0.5;
      }
    }
  }

  .current-time-line {
    height: 100%;
    width: 4px;
    background-color: var(--color-main);
    display: block;
    position: absolute;
    top: 0px;
    opacity: 0.4;
  }

  .old-reservation {
    position: absolute;
    background-color: var(--color-prev-background);
    min-width: var(--cellW);
    font-size: 12px;
    pointer-events: none;
    border-radius: 5px;
    user-select: none;
    -webkit-user-select: none;

    .full-name {
      position: absolute;
      top: 6px;
      left: 12px;
      padding-right: 28px;
      font-size: 11px;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

    }

    .number-of-people {
      position: absolute;
      top: 18px;
      left: 12px;
    }
  }

  .reservation {
    background-color: rgba(134, 191, 234, 0.1);
    min-width: var(--cellW);
    font-size: 12px;
    cursor: pointer;
    border: 3px dashed var(--sub-color);
    border-radius: 5px;
    user-select: none;
    -webkit-user-select: none;
    //z-index: 1;
    .centered-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .full-name {
      position: absolute;
      top: 1px;
      left: 2px;
      padding-right: 28px;
      font-size: 11px;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      &.merge-table {
        //left: 20px;
        padding-right: 45px;
        top: 20px;
      }
    }

    .number-of-people {
      position: absolute;
      top: 18px;
      left: 2px;
    }

    @keyframes border-blink {
      50% {
        border-color: transparent;
      }
    }

    &.overlap {
      animation: border-blink 1.5s;
      animation-iteration-count: infinite;
    }
  }
}

.drag-error {
  color: var(--color-error);
}

.time-expand {
  cursor: ew-resize;
  position: absolute;
  display: none;
  z-index: 1000;
}

.label-wrapper, .data-wrapper {


  .table-header-first {
    display: flex;
    background-color: #F1EFED;
    color: var(--color-main);
    font-weight: 600;
    z-index: 1999;
    height: 40px;
  }
}

.loading {
  z-index: 3000;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffffa1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-while {
  color: var(--color-main);
}

.reservation {
  font-weight: bold;

  &:hover {
    opacity: 0.7;
  }
}

.opacity-unset {
  opacity: unset !important;
}

.my-fork {
  background: var(--color-tt-course);
  padding: 1px;
  border-radius: 50%;
}

.my-bell {
  background: var(--color-tt-today);
  padding: 1px;
  border-radius: 50%;
}

.my-message {
  background: var(--color-tt-message);
  padding: 1px;
  border-radius: 50%;
}

.my-primary {
  background: var(--color-main);
  padding: 1px;
  border-radius: 50%;
}

.right-sidebar {
  background-color: var(--color-white);
  position: absolute;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 1995;
  width: 0%;
  transition: width 0.2s linear;
  display: flex;
}

.active {
  border: 5px solid var(--color-error) !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.expandedRightSidebar {
  background-color: var(--color-white);
  width: 580px;
  position: fixed;
}

/* css for ipad */
@media only screen and (min-width: 480px) and (max-width: 1000px) {
  .expandedRightSidebar {
    width: 580px;
  }
}
.reservation-full-screen {
  min-width: 100vw; /* Corrected from 100dvw */
  width: 100vw; /* Corrected from 100dvw */

  height: 100vh; /* Corrected from 100dvh */
  position: fixed; /* Stay in place */
  z-index: 2000; /* Sit on top */
  max-height: 100dvh;
  left: 0;
  top: 0;
  overflow: unset; /* Enable scroll if needed, kept this one and removed overflow: hidden */
  background-color: var(--main_opacity5); /* Added missing semicolon */}

/* css for mobile */
@media only screen and (max-width: 480px) {
  .expandedRightSidebar {
    width: 100%;
  }
  .swal-modal .swal-text {
    text-align: center;
  }
}

.reservation-main-container {
  display: flex;
}

.reservation-scroller {
  position: absolute;
  min-width: var(--cellW);
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: auto; /* Hides horizontal scrollbar */
  //height: 70dvh;
  z-index: 1997;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.reservation-scroller::-webkit-scrollbar {
  display: none;
}

.data-wrapper::-webkit-scrollbar {
  display: none;
}


.color-label {
  width: 20px;
  background: var(--color-tt-message);
  border-radius: 5px 0px 0px 5px;

}

.reservation-container {
  width: 100%;
  display: flex;
  border: 2px dashed var(--color-tt-finished);
  border-radius: 5px 5px 5px 5px;
}


.table-name {
  font-size: 14px;
  width: 8em;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-no {
  font-size: 12px;
}

.time-table-footer {
  position: fixed;
  background: transparent;
  right: 1.5rem;
  bottom: 3rem;
  height: 12rem; /* Adjust as needed */
  color: var(--color-main);
  text-align: right;
  z-index: 998;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: center;
  //line-height: 10rem; /* Center text vertically */
}

.reservation-time {
  position: absolute;
  margin: 4px 4px;
}

.icon-container {
  background-color: var(--sub-color);
  position: absolute;
  top: 6px;
  width: 30px;
  display: none;
}

.display-date {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: white;
  font-size: 20px;
  height: 60px;
  padding: 5px;
  text-align: center;
  color: var(--color-main);
}

.time-table-container::-webkit-scrollbar {
  display: none;
}
.reservation-footer-nav{
  width: 100dvw;
  position: absolute;
  bottom: 0px;
  height: 10dvh;
  background:var(--color-white);
  justify-content: space-between;
  align-items: center;
  padding: 2px 6px;
}

.confirm-button {
  float: right;
  padding: 0.6rem 5.7rem;
  background-color: #804000;
  color: #FFFFFF;
  border-color: #804000;
  font-size: 20px;
  border-radius: 6px;
  margin-top: 0.6rem;
  margin-right: 2rem;
}
.calendar-class {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
#reservation-data-wrapper {
  overflow-y: auto; /* Enables horizontal scrolling */
  position: relative; /* Ensures the sticky element is positioned relative to this container */
}

.table-wrapper {
  position: sticky; /* Makes the element sticky */
  top: 0; /* Sticks to the top of the container */
  z-index: 10; /* Ensures it stays above other content */
  background-color: white; /* Ensures the sticky element has a background */
  margin-right: 47px;
}
.circle {
  margin-right: 8px; /* Adjust the spacing between the icon and date */
}
//.table-wrapper{
//  position: sticky;
//  z-index:2005;
//}
.footer-button{
  margin-right:20px;
}
.data-scroll-wrapper{
  position: relative;
  overflow-x:auto;
  overflow-y: hidden;
}
.table-header::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
.calender-class{
  padding-left: 8px;
  padding-right: 8px;
  border-right: 1px solid #D9C6B2;
  border-left: 1px solid #D9C6B2;
  font-family: 'Noto Sans JP';
}
.circle {
  display: inline-block;
  height: 32px;
  width: 32px;
  background-color: var(--color-background-secondary);
  border-radius: 50%;
  top:-8px;
}
.justify-content-center {
  border: 2px solid var(--color-text-reservation);
  width: 20rem;
  border-radius: 10px;
}
.top-header {
  display: flex;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping of child elements */
  padding-right: 20px; /* Adds padding to avoid overlap with other elements */
}
.table-content{
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.table-header {
  display: flex;
  overflow-x:auto;
  background-color: var(--color-header-bg);
  color: var(--color-white);
  font-weight: 600;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  z-index: 1998;

  .hour {
    width: var(--colW);
    text-align: left;
    padding-left: 4px;
    border-right: 1px solid #66615b15;
  }
  //transition: all .2s ease-in;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 1;
}

.color-text{
  color: var(--color-text-reservation);
}


</style>