<template>
  <div
    class="dropdown-custom"
    ref="dropdownCustom"
    :class="{ disabled: disabled, alignLeft: alignLeft }"
  >
    <div
      class="dropdown-custom-toggle"
      @click="toggleDropdown"
      :class="{ open: isOpen }"
      tabindex="0"
    >
      <span
        class="dropdown-text"
        :class="{ 'disabled-text': selectedText === '' }"
      >
        {{ selectedText || placeholder }}

        <span v-if="alignLeft" class="icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="10"
                role="presentation"
                class="vs__open-indicator"
                >
                <path
                    d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
                ></path>
            </svg>
        </span>
      </span>
    </div>

    <ul v-show="isOpen" class="dropdown-custom-menu">
      <li
        v-for="(option, index) in options"
        :key="getOptionKey(option, index)"
        :class="{
          selected: isSelected(option),
          focused: index === focusedIndex,
        }"
        @click="selectOption(option)"
        @mouseover="focusedIndex = index"
        tabindex="0"
      >
        {{ getOptionText(option) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    options: Array, // Supports [{ id, name }] or [String, Number]
    value: [String, Number, Object, null],
    placeholder: {
      type: String,
      default: "選択してください",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      selected: this.value !== undefined ? this.value : "",
      focusedIndex: -1,
    };
  },
  computed: {
    selectedText() {
      if (this.selected === "") return "";
      const selectedOption = this.options.find(
        (opt) => this.getOptionValue(opt) === this.selected
      );
      return selectedOption
        ? this.getOptionText(selectedOption)
        : this.selected;
    },
  },
  watch: {
    value(newValue) {
      this.selected = newValue !== undefined ? newValue : "";
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      if (this.disabled) return;
      this.isOpen = !this.isOpen;
      if (this.isOpen) this.focusedIndex = -1;
    },
    selectOption(option) {
      this.selected = this.getOptionValue(option);
      this.isOpen = false;
      this.$emit("input", this.selected);
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdownCustom.contains(event.target)) {
        this.isOpen = false;
      }
    },
    getOptionText(option) {
      return typeof option === "object" ? (option.name ?? option.text) : option;
    },
    getOptionValue(option) {
      return typeof option === "object" ? (option.id !== undefined ? option.id : option.value) : option;
    },
    getOptionKey(option, index) {
      return typeof option === "object" ? option.id : index;
    },
    isSelected(option) {
      return this.getOptionValue(option) === this.selected;
    },
  },
};
</script>

<style scoped>
.dropdown-custom {
  position: relative;
  width: 100%;
  height: 48px;
}
.dropdown-custom-toggle {
  display: flex;
  align-items: center;
  justify-content: center; /* Center text */
  height: 48px;
  border: 1px solid #b4b4b4;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}
.dropdown-custom-toggle.open {
  border: 1px solid #b4b4b4;
}
.dropdown-text {
  font-size: 16px;
  flex: 1; /* Ensures text stays centered */
  text-align: center;
  color: var(--color-metal-grey);
}
.dropdown-icon {
  margin-left: auto; /* Push icon to the right */
  display: flex;
  align-items: center;
}
.dropdown-custom-menu {
  font-size: 16px;
  height: auto;
  max-height: 270px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  border: 1px solid #b4b4b4;
  background: white;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 11;
}
.dropdown-custom-menu li {
  cursor: pointer;
  font-weight: 400;
  color: var(--color-metal-grey);
  text-align:center;
}
.dropdown-custom-menu li.focused {
  color: white;
  background-color: var(--color-elem-hover);
}
.rotate-arrow {
  transform: rotate(180deg);
}
.dropdown-custom.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.alignLeft .dropdown-text,
.alignLeft .dropdown-custom-menu li {
  text-align: left;
  padding: 6px 12px;
}

.icon{
    position: absolute;
    right:8px;
    top:50%;
    transform: translateY(-50%);
}
</style>
